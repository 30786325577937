/**
 * FWaaS Data Provider
 */

import { isEmpty, has } from 'lodash';
import { httpClient } from '../httpClient';
import { ApplicationConfigManager } from "../types";
import * as DataModels from "./FwaasDataModels";
import * as DataTypes from "./FwaasDataTypes";
import * as EndPoints from "./FwaasUriEndPoints";
import {isSuccess, buildError, payloadToLowercaseParams} from "./utils";
import configuration from "../config.json"

const controllers: any = {
    fqdn: [],
    ruleStacks: [],
    firewalls: [],
    rules: [],
    prefix: [],
    users: [],
    accounts: [],
    feed: [],
    category: [],
    certificate: [],
    fileBlocking: [],
    predefinedUrlCategory: [],
    tokens: [],
    support: [],
    applications: [],
    xaccountroles: [],
    permissionpolicies: [],
};

const pushController = (resource: string, controller: any) => {
    const MAX_CONTROLLERS_QUEUE = 50;
    if (!(resource in controllers)) {
        throw new Error("unsupported resource [" + resource + "] for 'controllers'");
    }
    controllers[resource].unshift(controller);
    controllers[resource].splice(MAX_CONTROLLERS_QUEUE, 10);
};

/**
 * Factory class to perform CRUD of all Fwaas resources
 */
export class FwaasResourceFactory implements DataTypes.IResourceFactory {
    /**
     * Fwaas resource creation
     *
     * @param resource : (string) one of EndPoints.RESOURCE enums
     * @param payload : (any) object representing the resource to be created
     * @returns (IFwaasApiResponse) with 'data' or 'error' depending on success/failure
     */
    create(resource: string, payload: any): Promise<DataTypes.IFwaasApiResponse> {
        switch (resource) {
            case EndPoints.RESOURCE.USER:
                return createUser(payload as DataModels.CreateUserRoleMappingRequest);

            case EndPoints.RESOURCE.ACCOUNT:
                return createAccount(payload as DataModels.CreateLinkAccountRequest);

            case EndPoints.RESOURCE.FIREWALL:
                return createFirewall(payload as DataModels.CreateFWResourceRequest);

            case EndPoints.RESOURCE.RULESTACK:
                return createRuleStack(payload as DataModels.CreateRuleStackRequest);

            case EndPoints.RESOURCE.RULES:
                return createRule(payload as DataModels.CreateSecurityRuleRequest);

            case EndPoints.RESOURCE.FEED:
                return createFeed(payload as DataModels.CreateIntelligentFeedRequest);

            case EndPoints.RESOURCE.CERTIFICATE:
                return createCertificate(payload as DataModels.CreateIntelligentFeedRequest);

            case EndPoints.RESOURCE.PREFIX:
                return createPrefixList(payload as DataModels.CreatePrefixListRequest);

            case EndPoints.RESOURCE.FQDN:
                return createFQDNList(payload as DataModels.CreateFqdnListRequest);

            case EndPoints.RESOURCE.CATEGORY:
                return createCategories(payload as DataModels.CreateCustomURLCategoryRequest);

            case EndPoints.RESOURCE.SUBSCRIPTION:
                return createSubscription(payload as DataModels.CreateSubscribeRequest);

            case EndPoints.RESOURCE.VPCGROUPS:
                return createVpcGroup(payload as DataModels.CreateVpcGroupRequest);

            default:
                return Promise.reject("un-supported resource [" + resource + "] for 'create'");
        }
    }

    /**
     * Fwaas resource update
     *
     * @param resource : (string) one of EndPoints.RESOURCE enums
     * @param payload : (any) object representing the resource to be updated
     * @returns (IFwaasApiResponse) with 'data' or 'error' depending on success/failure
     */
    update(resource: string, payload: any): Promise<DataTypes.IFwaasApiResponse> {
        switch (resource) {
            case EndPoints.RESOURCE.USER:
                return updateUser(payload as DataModels.UpdateUserRoleMappingRequest);

            case EndPoints.RESOURCE.FIREWALL:
                if (payload.hasOwnProperty("Associate")) {
                    return associateRuleStack(payload as DataModels.AssociateRuleStackRequest);
                } else if (payload.hasOwnProperty("Disassociate")) {
                    return disassociateRuleStack(payload as DataModels.DisassociateRuleStackRequest);
                } else if (payload.hasOwnProperty("Description")) {
                    return updateFirewallDescription(payload as DataModels.UpdateFWResourceDescriptionRequest);
                } else if (payload.hasOwnProperty("AssociateSubnetMappings")) {
                    return updateFirewallSubnets(payload as DataModels.UpdateFWResourceSubnetsRequest);
                } else if (payload.hasOwnProperty("MultiVpcEnable")) {
                    return updateFirewallMultiVpcEnable(payload as DataModels.UpdateFWResourceSubnetsRequest);
                } else if (payload.hasOwnProperty("LinkId")) {
                    return updateFirewallAssociateLink(payload as any);
                } else if (payload.hasOwnProperty("Features")) {
                    return updateFirewallFeatures(payload as any);
                } else { // if(payload.hasOwnProperty("AppIdVersion")) {
                    return updateFirewallContentVersion(payload as DataModels.UpdateFWResourceContentVersionRequest);
                }

            case EndPoints.RESOURCE.RULESTACK:
                return updateRuleStack(payload as DataModels.UpdateRuleStackRequest);

            case EndPoints.RESOURCE.FEED:
                return updateFeed(payload as DataModels.UpdateIntelligentFeedRequest);

            case EndPoints.RESOURCE.CATEGORY:
                return updateCategories(payload as DataModels.UpdateCustomURLCategoryRequest);

            case EndPoints.RESOURCE.PREDEFINED:
                return updatePredefinedUrlCategory(payload as any);

            case EndPoints.RESOURCE.PREFIX:
                return updatePrefixList(payload as DataModels.UpdatePrefixListRequest);

            case EndPoints.RESOURCE.FQDN:
                return updateFQDNList(payload as DataModels.UpdateFqdnListRequest);

            case EndPoints.RESOURCE.CERTIFICATE:
                return updateCertificate(payload as DataModels.UpdateCertificateObjectRequest);

            case EndPoints.RESOURCE.RULES:
                return updateRule(payload as DataModels.UpdateSecurityRuleRequest);

            case EndPoints.RESOURCE.LOGPROFILE:
                return updateLogProfile(payload as DataModels.UpdateFWResourceLogProfileRequest);

            case EndPoints.RESOURCE.FILEBLOCKING:
                return updateFileBlocking(payload as DataModels.UpdateFileBlockingActionRequest);

            case EndPoints.RESOURCE.TOKEN:
                return updateTokens(payload as DataModels.UpdateProgrammaticAccessRequest);

            case EndPoints.RESOURCE.SUPPORT:
                return updateSupport(payload as DataModels.UpdateSupportRequest);

            case EndPoints.RESOURCE.TAGS:
                return updateTags(payload);

            case EndPoints.RESOURCE.SETTINGS:
                return updateSettings(payload);

            case EndPoints.RESOURCE.XACCOUNT_ROLES:
                return updateXAccountRoleArn(payload);

            case EndPoints.RESOURCE.ACCOUNT:
                return updateAccount(payload);

            case EndPoints.RESOURCE.VPCS:
                    return updateVpcs(payload);

            case EndPoints.RESOURCE.VPCGROUPS:
                return updateVpcGroups(payload);

            case EndPoints.RESOURCE.DLP_LINKS:
                return updateDlpLinks(payload as any);

            case EndPoints.RESOURCE.USER_MIGRATION:
                return handleUserMigration(payload as any);

            default:
                return Promise.reject("un-supported resource [" + resource + "] for 'update'");
        }
    }

    /**
     * Fwaas resource delete
     *
     * @param resource : (string) one of EndPoints.RESOURCE enums
     * @param payload : (any) object key of the resource to be deleted
     * @returns (IFwaasApiResponse) with 'data' or 'error' depending on success/failure
     */
    delete(resource: string, payload: any): Promise<DataTypes.IFwaasApiResponse> {
        switch (resource) {
            case EndPoints.RESOURCE.USER:
                return deleteUser(payload as DataModels.DeleteUserRoleMappingRequest);

            case EndPoints.RESOURCE.ACCOUNT:
                return deleteAccount(payload as DataModels.DeleteLinkAccountRequest);

            case EndPoints.RESOURCE.FIREWALL:
                return deleteFirewall(payload as DataModels.DeleteFWResourceRequest);

            case EndPoints.RESOURCE.RULESTACK:
                return deleteRuleStack(payload as DataModels.DeleteRuleStackRequest);

            case EndPoints.RESOURCE.FEED:
                return deleteFeed(payload as DataModels.DeleteIntelligentFeedRequest);

            case EndPoints.RESOURCE.PREFIX:
                return deletePrefixList(payload as DataModels.DeletePrefixListRequest);

            case EndPoints.RESOURCE.FQDN:
                return deleteFQDNList(payload as DataModels.DeleteFqdnListRequest);

            case EndPoints.RESOURCE.RULES:
                return deleteRule(payload as DataModels.DeleteSecurityRuleRequest);

            case EndPoints.RESOURCE.CERTIFICATE:
                return deleteCertificate(payload as DataModels.DeleteCertificateObjectRequest);

            case EndPoints.RESOURCE.CATEGORY:
                return deleteCategories(payload as DataModels.DeleteCustomURLCategoryRequest);

            case EndPoints.RESOURCE.TAGS:
                return deleteTags(payload);

            case EndPoints.RESOURCE.VPCGROUPS:
                return deleteVpcGroups(payload);

            default:
                return Promise.reject("un-supported resource [" + resource + "] for 'delete'");
        }
    }

    /**
     * Fwaas resource list details
     *
     * @param resource : (string) one of EndPoints.RESOURCE enums
     * @param payload : (any) object representing the resource to be created
     * @returns (IFwaasApiResponse) with 'data' or 'error' depending on success/failure
     */
    listDetail(resource: string, payload: any): Promise<DataTypes.IFwaasApiResponse> {
        switch (resource) {
            case EndPoints.RESOURCE.USER:
                return listUsersDetail(payload as DataModels.ListUserRoleMappingsRequest);

            case EndPoints.RESOURCE.ACCOUNT:
                return listAccountsDetail(payload as any);

            case EndPoints.RESOURCE.FIREWALL:
                return listFirewallsDetail(payload as DataModels.ListFWResourceRequest);

            case EndPoints.RESOURCE.RULESTACK:
                return listRuleStacksDetail(payload as DataModels.ListRuleStacksRequest);

            case EndPoints.RESOURCE.RULES:
                return listRuleDetail(payload as DataModels.ListSecurityRulesRequest);

            case EndPoints.RESOURCE.PREFIX:
                return listPrefixListDetail(payload as DataModels.ListPrefixListRequest);

            case EndPoints.RESOURCE.FQDN:
                return listFQDNListDetail(payload as DataModels.ListFqdnListRequest);

            case EndPoints.RESOURCE.CATEGORY:
                return listCategoriesDetails(payload as DataModels.ListCustomURLCategoriesRequest);

            case EndPoints.RESOURCE.FEED:
                return listFeedDetail(payload as DataModels.ListIntelligentFeedRequest);

            case EndPoints.RESOURCE.CERTIFICATE:
                return listCertificateDetail(payload as DataModels.ListCertificateObjectRequest);

            case EndPoints.RESOURCE.FILEBLOCKING:
                return listFileBlocking(payload as DataModels.ListFileBlockingActionRequest);

            case EndPoints.RESOURCE.PREDEFINED:
                return listMergedPredefinedUrlCategoryDetail(payload as DataModels.ListURLPredefinedCategoriesRequest);

            case EndPoints.RESOURCE.APPLICATIONS:
                return listApplicationDetail(payload as DataModels.ListAppIdVersionsRequest);

            case EndPoints.RESOURCE.TOKEN:
                return listTokensDetails(payload as DataModels.DescribeProgrammaticAccessRequest);

            case EndPoints.RESOURCE.XACCOUNT_ROLES:
                return listXAccountsDetails();

            case EndPoints.RESOURCE.VPCS:
                return listVPCDetails(payload as DataModels.ListVPCRequest);

            case EndPoints.RESOURCE.VPCTAGS:
                return listTagsDetails(payload as DataModels.ListTagsRequest);

            case EndPoints.RESOURCE.VPCTAGIPS:
                return listTagIPsDetails(payload as DataModels.ListTagIPsRequest);

            case EndPoints.RESOURCE.VPCGROUPS:
                return listGroupsDetails(payload as DataModels.ListTagsRequest);

            case EndPoints.RESOURCE.VPCPREFIXTAGS:
                return listPrefixDetails(payload as DataModels.ListPrefixRequest);

            default:
                return Promise.reject("un-supported resource [" + resource + "] for 'listDetail'");
        }
    }

    /**
     * Fwaas resource list
     *
     * @param resource : (string) one of EndPoints.RESOURCE enums
     * @param payload : (any) object representing the resource to be listed
     * @returns (IFwaasApiResponse) with 'data' or 'error' depending on success/failure
     */
    list(resource: string, payload: any, region: string | null): Promise<DataTypes.IFwaasApiResponse> {
        switch (resource) {
            case EndPoints.RESOURCE.USER:
                return listUsers(payload as DataModels.ListLinkAccountsRequest);

            case EndPoints.RESOURCE.ACCOUNT:
                return listAccounts(payload as DataModels.ListLinkAccountsRequest);

            case EndPoints.RESOURCE.FIREWALL:
                return listFirewalls(payload as DataModels.ListFWResourceRequest);

            case EndPoints.RESOURCE.RULESTACK:
                return listRuleStacks(payload as DataModels.ListRuleStacksRequest, '', region as string | null);

            case EndPoints.RESOURCE.RULES:
                return listRules(payload as DataModels.ListSecurityRulesRequest);

            case EndPoints.RESOURCE.PREFIXLIST:
                return listPrefixList(payload as DataModels.ListPrefixListRequest);

            case EndPoints.RESOURCE.FQDNLIST:
                return listFqdnList(payload as DataModels.ListFqdnListRequest);

            case EndPoints.RESOURCE.FEED:
                return listFeedList(payload as DataModels.ListIntelligentFeedRequest);

            case EndPoints.RESOURCE.PERMISSION_POLICIES:
                return listPermissionPolicies(payload as DataModels.ListPermissionPoliciesRequest);

            case EndPoints.RESOURCE.COUNTRIES:
                return listCountries(payload as DataModels.ListCountriesRequest);

            case EndPoints.RESOURCE.CATEGORY:
                return listCategories(payload as DataModels.ListCustomURLCategoriesRequest);

            case EndPoints.RESOURCE.PREDEFINED:
                return listPredefinedUrlCategory(payload as DataModels.ListURLPredefinedCategoriesRequest);

            case EndPoints.RESOURCE.LOGPROFILE:
                return describeLogProfile(payload as DataModels.ReadFWResourceLogProfileRequest);

            case EndPoints.RESOURCE.TOKEN:
                return listTokensDetails(payload as DataModels.DescribeProgrammaticAccessRequest);

            case EndPoints.RESOURCE.CERTIFICATE:
                return listCertificate(payload as DataModels.ListCertificateObjectRequest);

            case EndPoints.RESOURCE.SUBSCRIPTION:
                return listSubscription(payload as DataModels.ListSubscriptionRequest);

            case EndPoints.RESOURCE.XACCOUNT_ROLES:
                return listXAccountRoles(payload as DataModels.ListXAccountRolesRequest);

            default:
                return Promise.reject("un-supported resource [" + resource + "] for 'listDetail'");
        }
    }

    get(resource: string, payload: any): Promise<DataTypes.IFwaasApiResponse> {
        switch (resource) {
            case EndPoints.RESOURCE.CHECK_USER:
                return checkOktaUser(payload as DataModels.CheckOktaPayload);

            default:
                return Promise.reject("un-supported resource [" + resource + "] for 'listDetail'");
        }
    }

    getXml(resource: string, payload: any): Promise<DataTypes.IFwaasApiResponse> {
        switch (resource) {
            case EndPoints.RESOURCE.RULESTACK:
                return getRuleStackXML(payload as DataModels.DescribeRuleStackRequest);

            default:
                return Promise.reject("un-supported resource [" + resource + "] for 'listDetail'");
        }
    }

    /**
     * Fwaas resource describe
     *
     * @param resource : (string) one of EndPoints.RESOURCE enums
     * @param payload : (any) object representing the resource to be listed
     * @returns (IFwaasApiResponse) with 'data' or 'error' depending on success/failure
     */
    describe(resource: string, payload: any): Promise<DataTypes.IFwaasApiResponse> {
        switch (resource) {
            case EndPoints.RESOURCE.XACCOUNT_ROLES:
                return describeXAccountRoleArn(payload as DataModels.DescribeXAccountRoleArnRequest);
            case EndPoints.RESOURCE.FIREWALL:
                return describeFirewall(payload as DataModels.ReadFWResourceRequest);
            case EndPoints.RESOURCE.RULESTACK:
                return describeRuleStack(payload as DataModels.DescribeRuleStackRequest);
            case EndPoints.RESOURCE.RULES:
                return describeRule(payload as DataModels.DescribeSecurityRuleRequest);
            case EndPoints.RESOURCE.PREFIX:
                return describePrefixList(payload as DataModels.DescribePrefixListRequest);
            case EndPoints.RESOURCE.FQDN:
                return describeFQDNList(payload as DataModels.DescribeFqdnListRequest);
            case EndPoints.RESOURCE.CATEGORY:
                return describeCategories(payload as DataModels.DescribeCustomURLCategoryRequest);
            case EndPoints.RESOURCE.FEED:
                return describeFeed(payload as DataModels.DescribeIntelligentFeedRequest);
            case EndPoints.RESOURCE.CERTIFICATE:
                return describeCertificate(payload as DataModels.DescribeCertificateObjectRequest);
            case EndPoints.RESOURCE.USER:
                return describeUser(payload as DataModels.DescribeUserRoleMappingRequest);
            case EndPoints.RESOURCE.SUPPORT:
                return describeSupport(payload as DataModels.DescribeSupportRequest);
            case EndPoints.RESOURCE.RULE_COUNTERS:
                return describeRuleCounters(payload as DataModels.DescribeSecurityRuleCountersRequest);
            case EndPoints.RESOURCE.SETTINGS:
                return describeSettings(payload as any);
            case EndPoints.RESOURCE.BILLING:
                return describeBillings(payload as any);
            case EndPoints.RESOURCE.PANORAMA:
                return describePanorama();
            case EndPoints.RESOURCE.CLOUDMANAGER:
                return describeCloudManager();
            case EndPoints.RESOURCE.INTEGRATIONS:
                return describeIntegrations(payload as any);
            case EndPoints.RESOURCE.REGIONS:
                return describeRegions(payload as any);
            case EndPoints.RESOURCE.DEVICE_GROUPS:
                return describeDeviceGroups(payload as any);

            default:
                return Promise.reject("un-supported resource [" + resource + "] for 'describe'");
        }
    }

    /**
     * Fwaas resource refresh
     *
     * @param resource : (string) one of EndPoints.RESOURCE enums
     * @param payload : (any) object representing the resource to be listed
     * @returns (IFwaasApiResponse) with 'data' or 'error' depending on success/failure
     */
    refresh(resource: string, payload: any): Promise<DataTypes.IFwaasApiResponse> {
        switch (resource) {
            case EndPoints.RESOURCE.RULE_COUNTERS:
                return refreshRuleCounters(payload as DataModels.RefreshSecurityRuleCountersRequest);

            default:
                return Promise.reject("un-supported resource [" + resource + "] for 'refresh'");
        }
    }

    /**
     * Fwaas resource reset
     *
     * @param resource : (string) one of EndPoints.RESOURCE enums
     * @param payload : (any) object representing the resource to be listed
     * @returns (IFwaasApiResponse) with 'data' or 'error' depending on success/failure
     */
    reset(resource: string, payload: any): Promise<DataTypes.IFwaasApiResponse> {
        switch (resource) {
            case EndPoints.RESOURCE.RULE_COUNTERS:
                return resetRuleCounters(payload as DataModels.ResetSecurityRuleCountersRequest);

            default:
                return Promise.reject("un-supported resource [" + resource + "] for 'refresh'");
        }
    }

    /**
     * Fwaas resource commit
     *
     * @param resource : (string) one of EndPoints.RESOURCE enums
     * @param payload : (any) object representing the resource to be committed
     * @returns (IFwaasApiResponse) with 'data' or 'error' depending on success/failure
     */
    commit(resource: string, payload: any): Promise<DataTypes.IFwaasApiResponse> {
        switch (resource) {
            case EndPoints.RESOURCE.RULESTACK:
                return commit(payload as DataModels.CommitEntry);

            default:
                return Promise.reject("un-supported resource [" + resource + "] for 'listDetail'");
        }
    }

    /**
    * Fwaas resource commit
    *
    * @param resource : (string) one of EndPoints.RESOURCE enums
    * @param payload : (any) object representing the resource to be committed
    * @returns (IFwaasApiResponse) with 'data' or 'error' depending on success/failure
    */
    revert(resource: string, payload: any): Promise<DataTypes.IFwaasApiResponse> {
        switch (resource) {
            case EndPoints.RESOURCE.RULESTACK:
                return revert(payload as DataModels.RevertRequest);

            default:
                return Promise.reject("unsupported resource [" + resource + "] for 'revert'");
        }
    }

    /**
    * Fwaas resource commit
    *
    * @param resource : (string) one of EndPoints.RESOURCE enums
    * @param payload : (any) object representing the resource to be committed
    * @returns (IFwaasApiResponse) with 'data' or 'error' depending on success/failure
    */
    validate(resource: string, payload: any): Promise<DataTypes.IFwaasApiResponse> {
        switch (resource) {
            case EndPoints.RESOURCE.RULESTACK:
                return validate(payload as DataModels.ValidateRequest);

            default:
                return Promise.reject("unsupported resource [" + resource + "] for 'validate'");
        }
    }

    /**
    * Fwaas resource commit
    *
    * @param resource : (string) one of EndPoints.RESOURCE enums
    * @param payload : (any) object representing the resource to be committed
    * @returns (IFwaasApiResponse) with 'data' or 'error' depending on success/failure
    */
    commitstatus(resource: string, payload: any): Promise<DataTypes.IFwaasApiResponse> {
        switch (resource) {
            case EndPoints.RESOURCE.RULESTACK:
                return commitstatus(payload as DataModels.DescribeCommitRequest);

            default:
                return Promise.reject("unsupported resource [" + resource + "] for 'commitstatus'");
        }
    }

    /**
     * Fwaas resource abort
     * @param resource : (string) one of EndPoints.RESOURCE enums
     */
    abort(resource: string,) {
        if (resource in controllers) {
            controllers[resource].forEach((controller: any) => {
                if (controller) {
                    controller.abort();
                }
            });
            controllers[resource] = [];
        } else {
            throw new Error("unsupported or unimplemented resource [" + resource + "] for 'abort'");
        }
    }
}

/*
 * Private methods
 */

/**
 * CRUD operations for resource 'tenant'
 */

async function updateTokens(request: DataModels.UpdateProgrammaticAccessRequest): Promise<DataTypes.IFwaasApiResponse> {
    let payload = {
        Enabled: request.Enabled
        // MaxExpiryTime: (ge=480, le=1440) = 480) optional
    };
    return httpClient(getResourceURL(EndPoints.RESOURCE.TOKEN), {
        method: 'PUT',
        body: JSON.stringify(payload),
    })
        .then((response) => {
            let ret: DataTypes.IFwaasApiResponse = {};
            let responseObj = response.json;
            if (isSuccess(responseObj)) {
                ret.data = responseObj.Response;
            } else {
                ret.error = buildError(response, "");
            }
            return ret;
        })
        .catch((err) => {
            return { error: buildError(null, err) }
        });
}

async function listTokensDetails(request: DataModels.DescribeProgrammaticAccessRequest): Promise<DataTypes.IFwaasApiResponse> {
    return httpClient(getResourceURL(EndPoints.RESOURCE.TOKEN))
        .then(async (response: any) => {
            let ret: DataTypes.IFwaasApiResponse = {};
            let responseObj = response.json;
            if (isSuccess(responseObj)) {
                ret.data = responseObj.Response;
            } else {
                ret.error = buildError(response, "");
            }
            return ret;
        })
        .catch((err) => {
            return { error: buildError(null, err) }
        });
}


/**
 * CRUD operations for resource 'support'
 */

async function updateSupport(request: DataModels.UpdateSupportRequest): Promise<DataTypes.IFwaasApiResponse> {
    let payload = {
        SupportType: request.SupportType,
        SupportAccountId: request.SupportAccountId,
        Address: request.Address,
        PubSubMessageStatus: request.PubSubMessageStatus,
    };
    return httpClient(getResourceURL(EndPoints.RESOURCE.SUPPORT), {
        method: 'PUT',
        body: JSON.stringify(payload),
    })
        .then((response) => {
            let ret: DataTypes.IFwaasApiResponse = {};
            let responseObj = response.json;
            if (isSuccess(responseObj)) {
                ret.data = responseObj.Response;
            } else {
                ret.error = buildError(response, "");
            }
            return ret;
        })
        .catch((err) => {
            return { error: buildError(null, err) }
        });
}

async function describeSupport(request: DataModels.DescribeSupportRequest): Promise<DataTypes.IFwaasApiResponse> {
    let resourceUrl = getResourceURL(EndPoints.RESOURCE.SUPPORT);
    if (request && request?.ExistingSupportAccountsInfo) {
        resourceUrl += "?existingsupportaccountsinfo=true";
    }
    return httpClient(resourceUrl)
        .then(async (response) => {
            let ret: DataTypes.IFwaasApiResponse = {};
            let responseObj = response.json;
            if (isSuccess(responseObj)) {
                ret.data = responseObj.Response;
            } else {
                ret.error = buildError(response, "");
            }
            return ret;
        })
        .catch((err) => {
            return { error: buildError(null, err) }
        });
}

/**
 * CRUD operations for resource 'settings'
 */

async function describeSettings(request: any): Promise<DataTypes.IFwaasApiResponse> {
    let startDate = (request.startDate) ? `?start=${request.startDate}` : '';
    let endDate = (request.endDate) ? `&end=${request.endDate}` : '';
    let dimension = (request.dimension) ? `&dimension=${request.dimension}` : '';
    let resourceUrl = getResourceURL(EndPoints.RESOURCE.SETTINGS) + startDate;

    if (endDate) {
        resourceUrl = resourceUrl.concat(endDate);
    }
    if (dimension) {
        resourceUrl = resourceUrl.concat(dimension);
    }

    if (request.panorama) {
        resourceUrl += (startDate ? '&' : '?') + `panorama=${request.panorama}`;
    }

    if(request.networkmonitoringconfig) {
        resourceUrl += (startDate ? '&' : '?') + `networkmonitoringconfig=${request.networkmonitoringconfig}`;
    }

    if(request.featureflags) {
        resourceUrl += (startDate ? '&' : '?') + `featureflags=${request.featureflags}`;
    }

    return httpClient(resourceUrl)
        .then((response) => {
            let ret: DataTypes.IFwaasApiResponse = {};
            let responseObj = response.json;
            if (isSuccess(responseObj)) {
                ret.data = responseObj.Response;
            } else {
                ret.error = buildError(response, "");
            }
            return ret;
        })
        .catch((err) => {
            return { error: buildError(null, err) }
        });
}

async function updateSettings(request: any): Promise<DataTypes.IFwaasApiResponse> {
    const { payload, forceApiUrl } = request;
    return httpClient(getResourceURL(EndPoints.RESOURCE.SETTINGS, forceApiUrl), {
        method: 'PUT',
        body: JSON.stringify(payload),
    })
        .then((response) => {
            let ret: DataTypes.IFwaasApiResponse = {};
            let responseObj = response.json;
            if (isSuccess(responseObj)) {
                ret.data = responseObj.Response;
            } else {
                ret.error = buildError(response, "");
            }
            return ret;
        })
        .catch((err) => {
            return { error: buildError(null, err) }
        });
}

/* Billing describe endpoint */
async function describeBillings(request: any): Promise<DataTypes.IFwaasApiResponse> {
    let startDate = (request.startDate) ? `?start=${request.startDate}` : '';
    let endDate = (request.endDate) ? `&end=${request.endDate}` : '';
    let dimension = (request.dimension) ? `&dimension=${request.dimension}` : '';
    let resourceUrl = getResourceURL(EndPoints.RESOURCE.BILLING) + startDate;

    if (endDate) {
        resourceUrl = resourceUrl.concat(endDate);
    }
    if (dimension) {
        resourceUrl = resourceUrl.concat(dimension);
    }

    return httpClient(resourceUrl)
        .then((response) => {
            let ret: DataTypes.IFwaasApiResponse = {};
            let responseObj = response.json;
            if (isSuccess(responseObj)) {
                ret.data = responseObj.Response;
            } else {
                ret.error = buildError(response, "");
            }
            return ret;
        })
        .catch((err) => {
            return { error: buildError(null, err) }
        });
}


/**
 * CRUD operations for resource 'panorama'
 */

async function describePanorama(): Promise<DataTypes.IFwaasApiResponse> {
    let resourceUrl = getResourceURL(EndPoints.RESOURCE.PANORAMA);

    return httpClient(resourceUrl)
        .then((response: any) => {
            let ret: DataTypes.IFwaasApiResponse = {};
            let responseObj = response.json;
            if (isSuccess(responseObj)) {
                ret.data = responseObj.Response;
            } else {
                ret.error = buildError(response, "");
            }
            return ret;
        })
        .catch((err) => {
            return { error: buildError(null, err) }
        });
}

async function describeCloudManager(): Promise<DataTypes.IFwaasApiResponse> {
    let resourceUrl = getResourceURL(EndPoints.RESOURCE.CLOUDMANAGER);

    return httpClient(resourceUrl)
        .then((response: any) => {
            let ret: DataTypes.IFwaasApiResponse = {};
            let responseObj = response.json;
            if (isSuccess(responseObj)) {
                ret.data = responseObj.Response;
            } else {
                ret.error = buildError(response, "");
            }
            return ret;
        })
        .catch((err) => {
            return { error: buildError(null, err) }
        });
}

async function describeIntegrations(request: DataModels.DescribeIntegrationsRequest): Promise<DataTypes.IFwaasApiResponse> {
    let resourceUrl = getResourceURL(EndPoints.RESOURCE.INTEGRATIONS);
    if (request && request?.LinkId) {
        resourceUrl += `?linkid=${request?.LinkId}`;
    }

    return httpClient(resourceUrl)
        .then((response: any) => {
            let ret: DataTypes.IFwaasApiResponse = {};
            let responseObj = response.json;
            if (isSuccess(responseObj)) {
                ret.data = responseObj.Response;
            } else {
                ret.error = buildError(response, "");
            }
            return ret;
        })
        .catch((err) => {
            return { error: buildError(null, err) }
        });
}

/**
 * CRUD operations for resource 'regions'
 */

async function describeRegions(request: any): Promise<DataTypes.IFwaasApiResponse> {
    let resourceUrl = getResourceURL(EndPoints.RESOURCE.REGIONS);

    return httpClient(resourceUrl)
        .then((response: any) => {
            let ret: DataTypes.IFwaasApiResponse = {};
            let responseObj = response.json;
            if (isSuccess(responseObj)) {
                ret.data = responseObj.Response;
            } else {
                ret.error = buildError(response, "");
            }
            return ret;
        })
        .catch((err) => {
            return { error: buildError(null, err) }
        });
}

/**
 * CRUD operations for resource 'dgs' - device groups
 */

async function describeDeviceGroups(request: any): Promise<DataTypes.IFwaasApiResponse> {
    const {linkId} = request;
    const resourceUrl = getResourceURL(EndPoints.RESOURCE.DEVICE_GROUPS) + `?linkid=${linkId}`;
    return httpClient(resourceUrl)
        .then((response: any) => {
            let ret: DataTypes.IFwaasApiResponse = {};
            let responseObj = response.json;
            if (isSuccess(responseObj)) {
                ret.data = responseObj.Response;
            } else {
                ret.error = buildError(response, "");
            }
            return ret;
        })
        .catch((err) => {
            return { error: buildError(null, err) }
        });
}

/**
 * CRUD operations for resource 'dlp links'
 */

async function updateDlpLinks(request: any): Promise<any> {
    const {linkId, payload} = request;
    const resourceUrl = getResourceURL(EndPoints.RESOURCE.DLP_LINKS) + `/${linkId}`;
    let options = {
        method: 'GET'
    };
    if (payload && payload.options) {
        options = payload.options
    }
    return httpClient(resourceUrl, options)
        .then((response: any) => {
            let responseObj = response.json;
            if (isSuccess(responseObj)) {
                return {data: responseObj.Response};
            } else {
                return { error: buildError(response, "") }
            }
        })
        .catch((err) => {
            return { error: buildError(null, err) }
        });
}


/**
 * CRUD operations for resource 'tags'
 */

async function updateTags(request: any): Promise<DataTypes.IFwaasApiResponse> {
    const { resourceName, resourceType, payload } = request;
    const url = getResourceURL(EndPoints.RESOURCE.TAGS)
        .replace('{resourceName}', resourceName)
        .replace('{resourceType}', resourceType);

    if (resourceType === "ngfirewalls") {
        try {
            payload['AccountId'] = request.AccountId;
        } catch (e: any) {
            return { error: buildError(null, "Update Tags for Firewalls needs Account Id") }
        }
    }
    return httpClient(url, {
        method: 'POST',
        body: JSON.stringify(payload),
    })
        .then((response) => {
            let ret: DataTypes.IFwaasApiResponse = {};
            let responseObj = response.json;
            if (isSuccess(responseObj)) {
                ret.data = responseObj.Response;
            } else {
                ret.error = buildError(response, "");
            }
            return ret;
        })
        .catch((err) => {
            return { error: buildError(null, err) }
        });
}


/**
 * CRUD operations for resource 'user migration'
 */

async function handleUserMigration(request: any): Promise<any> {
    let payload = {
        FirstName: request.FirstName,
        LastName: request.LastName,
        UserName: request.UserName
    };
    const resourceUrl = getResourceURL(EndPoints.RESOURCE.USER_MIGRATION);
    let options = {
        method: 'PUT',
        body: JSON.stringify(payload),
    };

    return httpClient(resourceUrl, options)
        .then((response: any) => {
            let responseObj = response.json;
            if (isSuccess(responseObj)) {
                return {data: responseObj.Response};
            } else {
                return { error: buildError(response, "") }
            }
        })
        .catch((err) => {
            return { error: buildError(null, err) }
        });
}

async function deleteVpcGroups(request: any): Promise<DataTypes.IFwaasApiResponse> {
    let payload = {
        VpcGroupEntry: request?.VpcGroupEntry
    };
    const url = getResourceURL(EndPoints.RESOURCE.VPCGROUPS) + '/' + request?.Name;

    return httpClient(url, {
        method: 'DELETE'
    })
        .then((response) => {
            let ret: DataTypes.IFwaasApiResponse = {};
            let responseObj = response.json;
            if (isSuccess(responseObj)) {
                ret.data = responseObj.Response;
            } else {
                ret.error = buildError(response, responseObj);
            }
            return ret;
        })
        .catch((err) => {
            return { error: buildError(null, err) }
        });
}

async function deleteTags(request: any): Promise<DataTypes.IFwaasApiResponse> {
    const { resourceName, resourceType, payload } = request;
    const url = getResourceURL(EndPoints.RESOURCE.TAGS)
        .replace('{resourceName}', resourceName)
        .replace('{resourceType}', resourceType);

    if (resourceType === "ngfirewalls") {
        try {
            payload['AccountId'] = request.AccountId;
        } catch (e: any) {
            return { error: buildError(null, "Delete Tags for Firewalls needs Account Id") }
        }
    }
    return httpClient(url, {
        method: 'DELETE',
        body: JSON.stringify(payload),
    })
        .then((response) => {
            let ret: DataTypes.IFwaasApiResponse = {};
            let responseObj = response.json;
            if (isSuccess(responseObj)) {
                ret.data = responseObj.Response;
            } else {
                ret.error = buildError(response, "");
            }
            return ret;
        })
        .catch((err) => {
            return { error: buildError(null, err) }
        });
}

/**
 * CRUD operations for resource 'UserRoleMapping'
 */
async function createUser(request: DataModels.CreateUserRoleMappingRequest): Promise<DataTypes.IFwaasApiResponse> {
    return httpClient(getResourceURL(EndPoints.RESOURCE.USER), {
        method: 'POST',
        body: JSON.stringify(request),
    })
        .then((response) => {
            let ret: DataTypes.IFwaasApiResponse = {};
            let responseObj = response.json as DataModels.CreateUserRoleMappingResponse;
            if (isSuccess(responseObj)) {
                ret.data = new DataTypes.User().fromJSON(responseObj.Response);
            } else {
                ret.error = buildError(response, "");
            }
            return ret;
        })
        .catch((err) => {
            return buildError(null, err);
        });
}

async function updateUser(request: DataModels.UpdateUserRoleMappingRequest): Promise<DataTypes.IFwaasApiResponse> {
    let payload = {
        FirstName: request.FirstName,
        LastName: request.LastName,
        Permissions: request.Permissions
    };
    return httpClient(getResourceURL(EndPoints.RESOURCE.USER) + "/" + request.UserName, {
        method: 'PUT',
        body: JSON.stringify(payload),
    })
        .then((response) => {
            let ret: DataTypes.IFwaasApiResponse = {};
            let responseObj = response.json as DataModels.UpdateUserRoleMappingResponse;
            if (isSuccess(responseObj)) {
                ret.data = new DataTypes.User().fromJSON(responseObj.Response);
            } else {
                ret.error = buildError(response, "");
            }
            return ret;
        })
        .catch((err) => {
            return buildError(null, err);
        });
}

async function deleteUser(request: DataModels.DeleteUserRoleMappingRequest): Promise<DataTypes.IFwaasApiResponse> {
    return httpClient(getResourceURL(EndPoints.RESOURCE.USER) + "/" + request.UserName, {
        method: 'DELETE'
    })
        .then((response) => {
            let ret: DataTypes.IFwaasApiResponse = {};
            let responseObj = response.json as DataModels.DeleteUserRoleMappingResponse;
            if (isSuccess(responseObj)) {
                ret.data = new DataTypes.User().fromJSON(responseObj.Response);
            } else {
                ret.error = buildError(response, "");
            }
            return ret;
        })
        .catch((err) => {
            return buildError(null, err);
        });
}

async function listUsers(request: DataModels.ListUserRoleMappingsRequest): Promise<DataTypes.IFwaasApiResponse> {
    const controller = new AbortController();
    pushController('accounts', controller);

    return httpClient(getResourceURL(EndPoints.RESOURCE.USER), { signal: controller.signal })
        .then((response) => {
            let ret: DataTypes.IFwaasApiResponse = {};
            let responseObj = response.json as DataModels.ListUserRoleMappingsResponse;
            if (isSuccess(responseObj)) {
                ret.data = responseObj.Response as string[];
            } else {
                ret.error = buildError(response, "");
            }
            return ret;
        })
        .catch((err) => {
            return buildError(null, err);
        });
}

async function listUsersDetail(request: DataModels.ListUserRoleMappingsRequest): Promise<DataTypes.IFwaasApiResponse> {
    const controller = new AbortController();
    pushController('users', controller);
    var url = getResourceURL(EndPoints.RESOURCE.USER) + '?describe=true';

    if (request && request.NextToken) {
        url = url + `&maxresults=15&nexttoken=${request?.NextToken || undefined}`;
    } else {
        url = url + `&maxresults=15`;
    }

    return httpClient(url, { signal: controller.signal })
        .then(async (response: any) => {
            let ret: DataTypes.IFwaasApiResponse = {};
            let responseObj = response.json as DataModels.ListUserRoleMappingsResponse;
            if (isSuccess(responseObj)) {
                ret.data = responseObj.Response;
            } else {
                ret.error = buildError(response, "");
            }
            ret.nextToken = responseObj.Response?.NextToken;
            return ret;
        })
        .catch((err) => {
            return buildError(null, err, { noToast: controller.signal.aborted });
        });
}

async function describeUser(request: DataModels.DescribeUserRoleMappingRequest): Promise<DataTypes.IFwaasApiResponse> {
    const controller = new AbortController();
    pushController('users', controller);

    return httpClient(getResourceURL(EndPoints.RESOURCE.USER) + "/" + request.UserName, { signal: controller.signal })
        .then((response) => {
            let ret: DataTypes.IFwaasApiResponse = {};
            let responseObj = response.json as DataModels.DescribeUserRoleMappingResponse;
            if (isSuccess(responseObj)) {
                ret.data = new DataTypes.User().fromJSON(responseObj.Response);
            } else {
                ret.error = buildError(response, "");
            }
            return ret;
        })
        .catch((err) => {
            return buildError(null, err, { noToast: controller.signal.aborted });
        });
}

/**
 * CRUD operations for resource 'rulecounters'
 */
async function describeRuleCounters(request: DataModels.DescribeSecurityRuleCountersRequest): Promise<DataTypes.IFwaasApiResponse> {
    const { RuleStackName, RuleListName, Priority, FirewallName, AccountId } = request;
    let firewallParams = FirewallName ? `?firewallname=${FirewallName}&accountid=${AccountId}` : "";
    let url = getResourceURL(EndPoints.RESOURCE.RULE_COUNTERS).replace('{ruleStackName}', RuleStackName)
        .replace('{ruleListName}', RuleListName).replace('{priority}', String(Priority)) + firewallParams;
    return httpClient(url)
        .then((response) => {
            let ret: DataTypes.IFwaasApiResponse = {};
            let responseObj = response.json as DataModels.DescribeSecurityRuleCountersResponse;
            if (isSuccess(responseObj)) {
                ret.data = new DataTypes.User().fromJSON(responseObj.Response);
            } else {
                ret.error = buildError(response, "");
            }
            return ret;
        })
        .catch((err) => {
            return { error: buildError(null, err) }
        });
}

async function refreshRuleCounters(request: DataModels.RefreshSecurityRuleCountersRequest): Promise<DataTypes.IFwaasApiResponse> {
    const { RuleStackName, RuleListName, Priority, FirewallName, AccountId } = request;
    const headers = FirewallName ? { method: 'POST', body: JSON.stringify({ FirewallName, AccountId }) } : undefined;
    const url = getResourceURL(EndPoints.RESOURCE.RULE_COUNTERS).replace('{ruleStackName}', RuleStackName)
        .replace('{ruleListName}', RuleListName).replace('{priority}', String(Priority));
    return httpClient(url, headers)
        .then((response) => {
            let ret: DataTypes.IFwaasApiResponse = {};
            let responseObj = response.json as DataModels.RefreshSecurityRuleCountersResponse;
            if (isSuccess(responseObj)) {
                ret.data = new DataTypes.User().fromJSON(responseObj.Response);
            } else {
                ret.error = buildError(response, "");
            }
            return ret;
        })
        .catch((err) => {
            return { error: buildError(null, err) }
        });
}

async function resetRuleCounters(request: DataModels.ResetSecurityRuleCountersRequest): Promise<DataTypes.IFwaasApiResponse> {
    const { RuleStackName, RuleListName, Priority, FirewallName, AccountId } = request;
    const body = FirewallName ? { body: JSON.stringify({ FirewallName, AccountId }) } : undefined;
    const url = getResourceURL(EndPoints.RESOURCE.RULE_COUNTERS_RESET).replace('{ruleStackName}', RuleStackName)
        .replace('{ruleListName}', RuleListName).replace('{priority}', String(Priority));
    return httpClient(url, {
        method: 'POST',
        ...body,
    })
        .then((response) => {
            let ret: DataTypes.IFwaasApiResponse = {};
            let responseObj = response.json as DataModels.ResetSecurityRuleCountersResponse;
            if (isSuccess(responseObj)) {
                ret.data = new DataTypes.User().fromJSON(responseObj.Response);
            } else {
                ret.error = buildError(response, "");
            }
            return ret;
        })
        .catch((err) => {
            return { error: buildError(null, err) }
        });
}

/**
 * CRUD operations for resource 'linkaccounts'
 */
async function createAccount(request: DataModels.CreateLinkAccountRequest): Promise<DataTypes.IFwaasApiResponse> {
    return httpClient(getResourceURL(EndPoints.RESOURCE.ACCOUNT), {
        method: 'POST',
        body: JSON.stringify(request),
    })
        .then((response) => {
            let ret: DataTypes.IFwaasApiResponse = {};
            let responseObj = response.json as DataModels.CreateLinkAccountResponse;
            if (isSuccess(responseObj)) {
                ret.data = new DataTypes.Account().fromJSON(responseObj.Response);
            } else {
                ret.error = buildError(response, "");
            }
            return ret;
        })
        .catch((err) => {
            return buildError(null, err);
        });
}

async function deleteAccount(request: DataModels.DeleteLinkAccountRequest): Promise<DataTypes.IFwaasApiResponse> {
    return httpClient(getResourceURL(EndPoints.RESOURCE.ACCOUNT) + "/" + request.AccountId, {
        method: 'DELETE'
    })
        .then((response) => {
            let ret: DataTypes.IFwaasApiResponse = {};
            let responseObj = response.json as DataModels.DeleteLinkAccountResponse;
            if (isSuccess(responseObj)) {
                ret.data = new DataTypes.Account().fromJSON(responseObj.Response);
            } else {
                ret.error = buildError(response, "");
            }
            return ret;
        })
        .catch((err) => {
            return buildError(null, err);
        });
}

async function listAccounts(request: DataModels.ListLinkAccountsRequest): Promise<DataTypes.IFwaasApiResponse> {
    const controller = new AbortController();
    pushController('accounts', controller);

    return httpClient(getResourceURL(EndPoints.RESOURCE.ACCOUNT), { signal: controller.signal })
        .then((response) => {
            let responseObj = response.json as DataModels.ListLinkAccountsResponse;

            let ret: DataTypes.IFwaasApiResponse = {};
            if (isSuccess(responseObj)) {
                ret.data = responseObj.Response as string[];
            } else {
                ret.error = buildError(response, "");
            }

            return ret;
        })
        .catch((err) => {
            return buildError(null, err, { noToast: controller.signal.aborted });
        });
}

async function listAccountsDetail(request: any): Promise<DataTypes.IFwaasApiResponse> {
    let url = getResourceURL(EndPoints.RESOURCE.ACCOUNT) + '?describe=true&maxresults=25';
    if (request && request.NextToken) {
        url = url + `&nexttoken=${request?.NextToken || undefined}`;
    }
    return httpClient(url)
        .then(async (response: any) => {
            let responseObj = response.json as DataModels.ListLinkAccountsResponse;
            let ret: DataTypes.IFwaasApiResponse = {};

            if (isSuccess(responseObj)) {
                ret.data = responseObj.Response;
                ret.nextToken = responseObj.Response?.NextToken;
            } else {
                ret.error = buildError(response, "");
            }
            return ret;
        })
        .catch((err) => {
            return buildError(null, err)
        });
}

async function describeAccount(request: DataModels.DescribeLinkAccountRequest): Promise<DataTypes.IFwaasApiResponse> {
    return httpClient(getResourceURL(EndPoints.RESOURCE.ACCOUNT) + "/" + request.AccountId)
        .then((response) => {
            let responseObj = response.json as DataModels.DescribeLinkAccountResponse;

            let ret: DataTypes.IFwaasApiResponse = {};
            if (isSuccess(responseObj)) {
                ret.data = new DataTypes.Account().fromJSON(responseObj.Response);
            } else {
                ret.error = buildError(response, "");
            }

            return ret;
        })
        .catch((err) => {
            return buildError(null, err);
        });
}

/**
 * CRUD operations for resource 'support'
 */

async function updateAccount(request: any): Promise<DataTypes.IFwaasApiResponse> {
    return httpClient(getResourceURL(EndPoints.RESOURCE.ACCOUNT) + "/" + request.AccountId + "/updatetoken", {
        method: 'POST',
    })
        .then((response) => {
            let ret: DataTypes.IFwaasApiResponse = {};
            let responseObj = response.json;
            if (isSuccess(responseObj)) {
                ret.data = responseObj.Response;
            } else {
                ret.error = buildError(response, "");
            }
            return ret;
        })
        .catch((err) => {
            return { error: buildError(null, err) }
        });
}

/**
 * CRUD operations for resource 'vpcs'
 */

async function updateVpcs(request: any): Promise<DataTypes.IFwaasApiResponse> {
    let payload = request;
    return httpClient(getResourceURL(EndPoints.RESOURCE.VPCS), {
        method: 'POST',
        body: JSON.stringify(payload),
    })
        .then((response) => {
            let ret: DataTypes.IFwaasApiResponse = {};
            let responseObj = response.json;
            if (isSuccess(responseObj)) {
                ret.data = responseObj.Response;
            } else {
                ret.error = buildError(response, "");
            }
            return ret;
        })
        .catch((err) => {
            return { error: buildError(null, err) }
        });
}

async function updateVpcGroups(request: any): Promise<DataTypes.IFwaasApiResponse> {
    let payload = {
        VpcGroupEntry: request?.VpcGroupEntry
    };
    return httpClient(getResourceURL(EndPoints.RESOURCE.VPCGROUPS) + '/' + request?.Name, {
        method: 'PUT',
        body: JSON.stringify(payload),
    })
        .then((response) => {
            let ret: DataTypes.IFwaasApiResponse = {};
            let responseObj = response.json;
            if (isSuccess(responseObj)) {
                ret.data = responseObj.Response;
            } else {
                ret.error = buildError(response, "");
            }
            return ret;
        })
        .catch((err) => {
            return { error: buildError(null, err) }
        });
}

/**
 * CRUD operations for resource 'ngfirewalls'
 */
async function createFirewall(request: DataModels.CreateFWResourceRequest): Promise<DataTypes.IFwaasApiResponse> {
    return httpClient(getResourceURL(EndPoints.RESOURCE.FIREWALL), {
        method: 'POST',
        body: JSON.stringify(request),
    })
        .then((response) => {
            let ret: DataTypes.IFwaasApiResponse = {};
            let responseObj = response.json as DataModels.CreateFWResourceResponse;
            if (isSuccess(responseObj)) {
                ret.data = new DataTypes.Firewall().fromJSON(responseObj.Response);
            } else {
                ret.error = buildError(response, "");
            }
            return ret;
        })
        .catch((err) => {
            return buildError(null, err);
        });
}

async function updateFirewallDescription(request: DataModels.UpdateFWResourceDescriptionRequest): Promise<DataTypes.IFwaasApiResponse> {
    let payload = {
        Description: request.Description,
        AccountId: request.AccountId
    };
    return httpClient(getResourceURL(EndPoints.RESOURCE.FIREWALL) + "/" + request.FirewallName + "/description", {
        method: 'PUT',
        body: JSON.stringify(payload),
    })
        .then((response) => {
            let ret: DataTypes.IFwaasApiResponse = {};
            let responseObj = response.json as DataModels.UpdateFWResourceDescriptionResponse;
            if (isSuccess(responseObj)) {
                ret.data = responseObj.Response;
            } else {
                ret.error = buildError(response, "");
            }
            return ret;
        })
        .catch((err) => {
            return buildError(null, err);
        });
}

async function associateRuleStack(request: DataModels.AssociateRuleStackRequest): Promise<DataTypes.IFwaasApiResponse> {
    let payload = {
        RuleStackName: request.RuleStackName,
        AccountId: request.AccountId
    };
    return httpClient(getResourceURL(EndPoints.RESOURCE.FIREWALL) + "/" + request.FirewallName + "/rulestack", {
        method: 'POST',
        body: JSON.stringify(payload),
    })
        .then((response) => {
            let ret: DataTypes.IFwaasApiResponse = {};
            let responseObj = response.json as DataModels.AssociateRuleStackResponse;
            if (isSuccess(responseObj)) {
                ret.data = responseObj.Response;
            } else {
                ret.error = buildError(response, "");
            }
            return ret;
        })
        .catch((err) => {
            return buildError(null, err);
        });
}

async function disassociateRuleStack(request: DataModels.DisassociateRuleStackRequest): Promise<DataTypes.IFwaasApiResponse> {
    let payload = {
        AccountId: request.AccountId
    };

    return httpClient(getResourceURL(EndPoints.RESOURCE.FIREWALL) + "/" + request.FirewallName + "/rulestack", {
        method: 'DELETE',
        body: JSON.stringify(payload),
    })
        .then((response) => {
            let ret: DataTypes.IFwaasApiResponse = {};
            let responseObj = response.json as DataModels.DisassociateRuleStackResponse;
            if (isSuccess(responseObj)) {
                ret.data = responseObj.Response;
            } else {
                ret.error = buildError(response, "");
            }
            return ret;
        })
        .catch((err) => {
            return buildError(null, err);
        });
}



async function updateFirewallContentVersion(request: DataModels.UpdateFWResourceContentVersionRequest): Promise<DataTypes.IFwaasApiResponse> {
    let payload = {
        AppIdVersion: request.AppIdVersion,
        AccountId: request.AccountId
    };
    return httpClient(getResourceURL(EndPoints.RESOURCE.FIREWALL) + "/" + request.FirewallName + "/contentversion", {
        method: 'PUT',
        body: JSON.stringify(payload),
    })
        .then((response) => {
            let ret: DataTypes.IFwaasApiResponse = {};
            let responseObj = response.json as DataModels.UpdateFWResourceContentVersionResponse;
            if (isSuccess(responseObj)) {
                ret.data = responseObj.Response;
            } else {
                ret.error = buildError(response, "");
            }
            return ret;
        })
        .catch((err) => {
            return buildError(null, err);
        });
}

async function updateFirewallSubnets(request: DataModels.UpdateFWResourceSubnetsRequest): Promise<DataTypes.IFwaasApiResponse> {
    let payload = {
        AssociateSubnetMappings: request.AssociateSubnetMappings,
        DisassociateSubnetMappings: request.DisassociateSubnetMappings,
        AccountId: request.AccountId
    };
    if (has(request, 'MultiVpcEnable')) {
        //@ts-ignore
        payload['MultiVpcEnable'] = request?.MultiVpcEnable;
    }
    return httpClient(getResourceURL(EndPoints.RESOURCE.FIREWALL) + "/" + request.FirewallName + "/subnets", {
        method: 'PUT',
        body: JSON.stringify(payload),
    })
        .then((response) => {
            let ret: DataTypes.IFwaasApiResponse = {};
            let responseObj = response.json as DataModels.UpdateFWResourceSubnetsResponse;
            if (isSuccess(responseObj)) {
                ret.data = responseObj.Response;
            } else {
                ret.error = buildError(response, "");
            }
            return ret;
        })
        .catch((err) => {
            return buildError(null, err);
        });
}

async function updateFirewallMultiVpcEnable(request: DataModels.UpdateFWResourceSubnetsRequest): Promise<DataTypes.IFwaasApiResponse> {
    let payload = {
        MultiVpcEnable: request.MultiVpcEnable,
        AccountId: request.AccountId
    };
    return httpClient(getResourceURL(EndPoints.RESOURCE.FIREWALL) + "/" + request.FirewallName + "/subnets", {
        method: 'PUT',
        body: JSON.stringify(payload),
    })
        .then((response) => {
            let ret: DataTypes.IFwaasApiResponse = {};
            let responseObj = response.json as DataModels.UpdateFWResourceSubnetsResponse;
            if (isSuccess(responseObj)) {
                ret.data = responseObj.Response;
            } else {
                ret.error = buildError(response, "");
            }
            return ret;
        })
        .catch((err) => {
            return buildError(null, err);
        });
}

async function updateFirewallAssociateLink(request: any): Promise<DataTypes.IFwaasApiResponse> {
    let payload = ((request.Unlink)) ? {AccountId: request.AccountId} : {
        LinkId: request.LinkId,
        AccountId: request.AccountId
    };
    return httpClient(getResourceURL(EndPoints.RESOURCE.FIREWALL) + "/" + request.FirewallName + "/link", {
        method: (request.Unlink) ? 'DELETE' : 'PUT',
        body: JSON.stringify(payload),
    })
        .then((response: any) => {
            let ret: DataTypes.IFwaasApiResponse = {};
            let responseObj = response.json as any;
            if (isSuccess(responseObj)) {
                ret.data = responseObj.Response;
            } else {
                ret.error = buildError(response, "");
            }
            return ret;
        })
        .catch((err) => {
            return buildError(null, err);
        });
}

async function updateFirewallFeatures(request: any): Promise<DataTypes.IFwaasApiResponse> {
    let payload = {
        AccountId: request.AccountId,
        Features: request.Features
    };
    return httpClient(getResourceURL(EndPoints.RESOURCE.FIREWALL) + "/" + request.FirewallName + "/features", {
        method: 'PUT',
        body: JSON.stringify(payload),
    })
        .then((response: any) => {
            let ret: DataTypes.IFwaasApiResponse = {};
            let responseObj = response.json as any;
            if (isSuccess(responseObj)) {
                ret.data = responseObj.Response;
            } else {
                ret.error = buildError(response, "");
            }
            return ret;
        })
        .catch((err) => {
            return buildError(null, err);
        });
}

async function deleteFirewall(request: DataModels.DeleteFWResourceRequest): Promise<DataTypes.IFwaasApiResponse> {
    return httpClient(getResourceURL(EndPoints.RESOURCE.FIREWALL) + "/" + request.FirewallName, {
        method: 'DELETE',
        body: JSON.stringify({
            Force: request?.Force ?? false,
            AccountId: request?.AccountId
        }),
    })
        .then((response) => {
            let ret: DataTypes.IFwaasApiResponse = {};
            let responseObj = response.json as DataModels.DeleteFWResourceResponse;
            if (isSuccess(responseObj)) {
                ret.data = new DataTypes.Firewall().fromJSON(responseObj.Response);
            } else {
                ret.error = buildError(response, "");
            }
            return ret;
        })
        .catch((err) => {
            return buildError(null, err);
        });
}

async function listFirewalls(request: DataModels.ListFWResourceRequest): Promise<DataTypes.IFwaasApiResponse> {
    if (ApplicationConfigManager.getInstance().getConfig().tenantVersion === "V2") {
        request['region'] = ApplicationConfigManager.getInstance().getConfig().currentRegion.RegionCode;
    }
    //@ts-ignore
    const urlParams = new URLSearchParams(request);
    let resourceUrl = ApplicationConfigManager.getInstance().getConfig().tenantVersion === "V2" ?
        `${configuration.v2ApiUrl}/v2/config/ngfirewalls` : getResourceURL(EndPoints.RESOURCE.FIREWALL);
    // TBD payload need to be attached
    return httpClient(resourceUrl + '?' + urlParams.toString())
        .then((response) => {
            let ret: DataTypes.IFwaasApiResponse = {};
            let responseObj = response.json as DataModels.ListFWResourceResponse;
            if (isSuccess(responseObj)) {
                ret.data = responseObj.Response?.Firewalls as DataModels.FirewallInfo[];
            } else {
                ret.error = buildError(response, "");
            }
            return ret;
        })
        .catch((err) => {
            return buildError(null, err);
        });
}

async function listFirewallsDetail(request: DataModels.ListFWResourceRequest): Promise<DataTypes.IFwaasApiResponse> {
    // TBD payload need to be attached
    let resourceUrl = ApplicationConfigManager.getInstance().getConfig().tenantVersion === "V2" ?
        `${configuration.v2ApiUrl}/v2/config/ngfirewalls` : getResourceURL(EndPoints.RESOURCE.FIREWALL);

    if (request && request.NextToken) {
        resourceUrl = resourceUrl + `?describe=true&maxresults=25&nexttoken=${request?.NextToken || undefined}`;
    } else {
        resourceUrl = resourceUrl + `?describe=true&maxresults=25`;
    }
    if (request && request.RuleStackName) {
        resourceUrl += '&rulestackname=' + request.RuleStackName;
    }
    if (ApplicationConfigManager.getInstance().getConfig().tenantVersion === "V2") {
        resourceUrl += '&region=' + ApplicationConfigManager.getInstance().getConfig().currentRegion.RegionCode;
    }

    const controller = new AbortController();
    pushController('firewalls', controller);

    return httpClient(resourceUrl, { signal: controller.signal })
        .then(async (response: any) => {
            let ret: DataTypes.IFwaasApiResponse = {};
            let responseObj = response.json as DataModels.ListFWResourceResponse;
            let firewalls: DataTypes.Firewall[] = [];
            if (isSuccess(responseObj)) {
                let index: number = Math.floor(100000 + Math.random() * 900000);
                let firewallNames = responseObj.Response?.FirewallsDescribe as string[];
                if (firewallNames) {
                    ret.data = firewallNames;
                }
                if (firewallNames) {
                    firewallNames.map((r: any) => {
                        r.id = index++;
                        firewalls.push(r);
                    })
                    ret.data = firewalls;
                }
            } else {
                ret.error = buildError(response, "");
            }
            ret.nextToken = responseObj.Response?.NextToken;
            return ret;
        })
        .catch((err) => {
            return buildError(null, err, { noToast: controller.signal.aborted })
        });
}

async function describeFirewall(request: DataModels.ReadFWResourceRequest): Promise<DataTypes.IFwaasApiResponse> {
    const controller = new AbortController();
    pushController('firewalls', controller);

    return httpClient(getResourceURL(EndPoints.RESOURCE.FIREWALL) + "/" + request.FirewallName + "?accountid=" + request.AccountId, { signal: controller.signal })
        .then((response) => {
            let ret: DataTypes.IFwaasApiResponse = {};
            let responseObj = response.json as DataModels.ReadFWResourceResponse;
            if (isSuccess(responseObj)) {
                ret.data = new DataTypes.Firewall().fromJSON(responseObj.Response);
            } else {
                ret.error = buildError(response, "");
            }
            return ret;
        })
        .catch((err) => {
            return buildError(null, err, { noToast: controller.signal.aborted });
        });
}

/**
 * CRUD operations for resource 'countries'
 */
async function listCountries(request: DataModels.ListCountriesRequest, prevData: any = {}): Promise<DataTypes.IFwaasApiResponse> {
    let resourceUrl = getResourceURL(EndPoints.RESOURCE.COUNTRY);
    if (request.NextToken) {
        resourceUrl = resourceUrl + `?nexttoken=${request.NextToken}`;
    }

    return httpClient(resourceUrl)
        .then(async (response: any) => {
            let ret: DataTypes.IFwaasApiResponse = {};
            let responseObj = response.json as DataModels.ListCountriesResponse;
            if (isSuccess(responseObj)) {
                ret.data = responseObj.Response as any;
                if (!isEmpty(prevData)) {
                    ret.data.CountryCodes = [...ret.data.CountryCodes, ...prevData?.data?.CountryCodes];
                }
            } else {
                ret.error = buildError(response, "");
            }
            if (ret.data.NextToken && ret.data.NextToken !== "None") {
                request.NextToken = ret.data.NextToken;
                return await listCountries(request, ret);
            }
            ret.data = ret.data.CountryCodes as DataModels.CountryData[];
            return ret;
        })
        .catch((err) => {
            return buildError(null, err);
        });
}

/**
 * CRUD operations for resource 'rulestacks'
 */
async function createRuleStack(request: DataModels.CreateRuleStackRequest): Promise<DataTypes.IFwaasApiResponse> {
    return httpClient(getResourceURL(EndPoints.RESOURCE.RULESTACK), {
        method: 'POST',
        body: JSON.stringify(request),
    })
        .then((response) => {
            let ret: DataTypes.IFwaasApiResponse = {};
            let responseObj = response.json as DataModels.CreateRuleStackResponse;
            if (isSuccess(responseObj)) {
                ret.data = new DataTypes.RuleStack().fromJSON(responseObj.Response);
            } else {
                ret.error = buildError(response, "");
            }
            return ret;
        })
        .catch((err) => {
            return buildError(null, err);
        });
}

async function updateRuleStack(request: DataModels.UpdateRuleStackRequest): Promise<DataTypes.IFwaasApiResponse> {
    let payload = { RuleStackEntry: request.RuleStackEntry };
    return httpClient(getResourceURL(EndPoints.RESOURCE.RULESTACK) + "/" + request.RuleStackName, {
        method: 'PUT',
        body: JSON.stringify(payload),
    })
        .then((response) => {
            let ret: DataTypes.IFwaasApiResponse = {};
            let responseObj = response.json as DataModels.UpdateRuleStackResponse;
            if (isSuccess(responseObj)) {
                ret.data = new DataTypes.RuleStack().fromJSON(responseObj.Response?.RuleStackEntry);
            } else {
                ret.error = buildError(response, "");
            }
            return ret;
        })
        .catch((err) => {
            return buildError(null, err);
        });
}

async function deleteRuleStack(request: DataModels.DeleteRuleStackRequest): Promise<DataTypes.IFwaasApiResponse> {
    return httpClient(getResourceURL(EndPoints.RESOURCE.RULESTACK) + "/" + request.RuleStackName, {
        method: 'DELETE'
    })
        .then((response) => {
            let ret: DataTypes.IFwaasApiResponse = {};
            let responseObj = response.json as DataModels.DeleteRuleStackResponse;
            if (isSuccess(responseObj)) {
                ret.data = new DataTypes.RuleStack().fromJSON(responseObj.Response);
            } else {
                ret.error = buildError(response, "");
            }
            return ret;
        })
        .catch((err) => {
            return buildError(null, err);
        });
}

async function listRuleStacks(request: DataModels.ListRuleStacksRequest = {}, prevData: any = {}, region?: string | null): Promise<DataTypes.IFwaasApiResponse> {
    let resourceUrl = new URL(getResourceURL(EndPoints.RESOURCE.RULESTACK, "", region));
    var search_params = resourceUrl.searchParams;
    if (request.Candidate !== undefined) {
        search_params.set('candidate', request.Candidate.toString());
        //resourceUrl += `?candidate=${request.Candidate}`;
    }
    if (request.Running !== undefined) {
        search_params.set('running', request.Running.toString());
    }
    if (request.Scope !== undefined) {
        search_params.set('scope', request.Scope.toString());
    }
    if (request.NextToken !== undefined) {
        search_params.set('nexttoken', request.NextToken.toString());
    }
    const controller = new AbortController();
    pushController('ruleStacks', controller);
    return httpClient(resourceUrl.toString(), { signal: controller.signal })
        .then(async (response: any) => {
            let ret: DataTypes.IFwaasApiResponse = {};
            let responseObj = response.json as DataModels.ListRuleStacksResponse;
            if (isSuccess(responseObj)) {
                ret.data = responseObj.Response as any;
                if (!isEmpty(prevData)) {
                    if (request.Candidate === false && request.Running === true) {
                        ret.data.RuleStackRunning = [...prevData?.data?.RuleStackRunning, ...ret.data.RuleStackRunning]
                    } else {
                        ret.data.RuleStackCandidate = [...prevData?.data?.RuleStackCandidate, ...ret.data.RuleStackCandidate];
                    }
                }
            } else {
                ret.error = buildError(response, "");
            }
            if (ret.data.NextToken && ret.data.NextToken !== "None") {
                request.NextToken = ret.data.NextToken;
                return await listRuleStacks(request, ret);
            }
            if (request.Candidate === false && request.Running === true) {
                ret.data = (ret.data.RuleStackRunning) ? ret.data.RuleStackRunning : [];
            } else {
                ret.data = (ret.data.RuleStackCandidate) ? ret.data.RuleStackCandidate : [];
            }

            if (!isEmpty(ret.data) && isEmpty(request.Scope)) {
                const localRuleStack = await listRuleStacks({Scope: 'Local'});
                let ruleStacks: { Name: any; Scope?: string; }[] = [];
                ret.data.map((d: any) => {
                    const index = localRuleStack.data.findIndex((ld: any) => ld === d);
                    if (index > -1) {
                        ruleStacks.push({Name: d, Scope: 'Local'});
                    } else {
                        ruleStacks.push({Name: d});
                    }
                });
                ret.data = ruleStacks;
            }

            return ret;
        })
        .catch((err) => {
            return buildError(null, err, { noToast: controller.signal.aborted })
        });
}

async function listRuleStacksDetail(request: DataModels.ListRuleStacksRequest): Promise<DataTypes.IFwaasApiResponse> {
    let resourceUrl = getResourceURL(EndPoints.RESOURCE.RULESTACK);
    if (request && request.NextToken) {
        resourceUrl = resourceUrl + `?describe=true&maxresults=25&nexttoken=${request?.NextToken || undefined}`;
    } else {
        resourceUrl = resourceUrl + `?describe=true&maxresults=25`;
    }
    const controller = new AbortController();
    pushController('ruleStacks', controller);
    return httpClient(resourceUrl, { signal: controller.signal })
        .then(async (response: any) => {
            let ret: DataTypes.IFwaasApiResponse = {};
            let responseObj = response.json as DataModels.ListRuleStacksResponse;
            let rulestacks: DataTypes.RuleStack[] = [];
            if (isSuccess(responseObj)) {
                let index: number = Math.floor(100000 + Math.random() * 900000);
                let ruleStackNames = responseObj.Response?.RuleStackCandidateDescribe as string[];
                if (ruleStackNames) {
                    ret.data = ruleStackNames;
                }
                if (ruleStackNames) {
                    ruleStackNames.map((r: any) => {
                        r.id = index++;
                        rulestacks.push(r);
                    })
                    ret.data = rulestacks;
                }
            } else {
                ret.error = buildError(response, "");
            }
            ret.nextToken = responseObj.Response?.NextToken;
            return ret;
        })
        .catch((err) => {
            return buildError(null, err, { noToast: controller.signal.aborted })
        });
}

async function describeRuleStack(request: DataModels.DescribeRuleStackRequest): Promise<DataTypes.IFwaasApiResponse> {
    let url = getResourceURL(EndPoints.RESOURCE.RULESTACK) + "/" + request.RuleStackName;
    url = request.Running ? url + '?running=true' : url;
    return httpClient(url)
        .then((response) => {
            let ret: DataTypes.IFwaasApiResponse = {};
            let responseObj = response.json as DataModels.DescribeRuleStackResponse;
            if (isSuccess(responseObj)) {
                ret.data = responseObj.Response as DataTypes.RuleStack;
            } else {
                ret.error = buildError(response, "");
            }
            return ret;
        })
        .catch((err) => {
            return buildError(null, err);
        });
}

async function getRuleStackXML(request: DataModels.DescribeRuleStackRequest): Promise<DataTypes.IFwaasApiResponse> {
    let url = getResourceURL(EndPoints.RESOURCE.RULESTACK) + "/" + request.RuleStackName + "/xml";
    return httpClient(url)
        .then((response) => {
            let ret: DataTypes.IFwaasApiResponse = {};
            let responseObj = response.json as DataModels.DescribeRuleStackResponse;
            if (isSuccess(responseObj)) {
                ret.data = responseObj.Response as DataTypes.RuleStack;
            } else {
                ret.error = buildError(response, "");
            }
            return ret;
        })
        .catch((err) => {
            return buildError(null, err);
        });
}




/**
 * CRUD operations for resource 'rules'
 */
async function createRule(request: DataModels.CreateSecurityRuleRequest): Promise<DataTypes.IFwaasApiResponse> {
    let resourceUrl = getResourceURL(EndPoints.RESOURCE.RULES).replace('{ruleStackName}',
        request.RuleStackName).replace('{ruleListName}', request.RuleListName);

    interface payloadInterface extends Omit<DataModels.CreateSecurityRuleRequest, 'RuleStackName' | 'RuleListName'> { }
    let payload = request as payloadInterface;

    //@ts-ignore
    if (payload.RuleStackName) { delete payload['RuleStackName'] };
    //@ts-ignore
    if (payload.RuleListName) { delete payload['RuleListName'] };
    // CHECK add LocalRule or actual rule name
    return httpClient(resourceUrl, {
        method: 'POST',
        body: JSON.stringify(request),
    })
        .then((response) => {
            let ret: DataTypes.IFwaasApiResponse = {};
            let responseObj = response.json as DataModels.CreateSecurityRuleResponse;
            if (isSuccess(responseObj)) {
                ret.data = new DataTypes.Rule().fromJSON(responseObj.Response);
            } else {
                ret.error = buildError(response, "");
            }
            return ret;
        })
        .catch((err) => {
            return buildError(null, err);
        });
}


async function listRules(request: DataModels.ListSecurityRulesRequest): Promise<DataTypes.IFwaasApiResponse> {
    let resourceUrl = getResourceURL(EndPoints.RESOURCE.RULES).replace('{ruleStackName}',
        request?.RuleStackName).replace('{ruleListName}', request?.RuleListName);

    if (request && request.NextToken) {
        resourceUrl = resourceUrl + `?maxresults=1000&nexttoken=${request?.NextToken || undefined}`;
    } else {
        resourceUrl = resourceUrl + `?maxresults=1000`;
    }

    return httpClient(resourceUrl)
        .then((response: any) => {
            let responseObj = response.json as DataModels.ListSecurityRulesResponse;

            let ret: DataTypes.IFwaasApiResponse = {};
            if (isSuccess(responseObj)) {
                ret.data = responseObj.Response?.RuleEntryCandidate as DataModels.RuleEntryIdentifier[];
            } else {
                ret.error = buildError(response, "");
            }
            ret.nextToken = responseObj.Response?.NextToken;
            return ret;
        })
        .catch((err) => {
            return buildError(null, err);
        });
}

async function listRuleDetail(request: DataModels.ListSecurityRulesRequest): Promise<DataTypes.IFwaasApiResponse> {
    let resourceUrl = getResourceURL(EndPoints.RESOURCE.RULES).replace('{ruleStackName}',
        request?.RuleStackName).replace('{ruleListName}', request?.RuleListName);
    if (request && request.NextToken) {
        resourceUrl = resourceUrl + `?describe=true&maxresults=50&nexttoken=${request?.NextToken || undefined}`;
    } else {
        resourceUrl = resourceUrl + `?describe=true&maxresults=50`;
    }
    const controller = new AbortController();
    pushController('rules', controller);
    return httpClient(resourceUrl, { signal: controller.signal })
        .then(async (response: any) => {
            let ret: DataTypes.IFwaasApiResponse = {};
            let responseObj = response.json as DataModels.ListSecurityRulesResponse;
            let rules: DataTypes.Rule[] = [];
            if (isSuccess(responseObj)) {
                let index: number = Math.floor(100000 + Math.random() * 900000);
                //@ts-ignore
                let response2 = responseObj.Response?.RuleEntryCandidateDescribe as DataModels.RuleDescribe[];
                if (response2) {
                    response2.map((r: any) => {
                        r.id = index++;
                        r.RuleStackName = responseObj.Response?.RuleStackName;
                        r.RuleListName = responseObj.Response?.RuleListName;
                        rules.push(r);
                    })
                    ret.data = rules; // CHECK return value
                }
            } else {
                ret.error = buildError(response, "");
            }
            ret.nextToken = responseObj.Response?.NextToken;
            return ret;
        })
        .catch((err) => {
            return buildError(null, err, { noToast: controller.signal.aborted });
        });
}

async function updateRule(request: DataModels.UpdateSecurityRuleRequest): Promise<DataTypes.IFwaasApiResponse> {
    let resourceUrl = getResourceURL(EndPoints.RESOURCE.RULES).replace('{ruleStackName}',
        request.RuleStackName).replace('{ruleListName}', request.RuleListName);
    resourceUrl = resourceUrl + "/priorities/" + request.Priority;

    interface payloadInterface extends Omit<DataModels.UpdateSecurityRuleRequest, 'RuleStackName' | 'RuleListName'> { }
    let payload = request as payloadInterface;

    //@ts-ignore
    if (payload.RuleStackName) { delete payload['RuleStackName'] };
    //@ts-ignore
    if (payload.RuleListName) { delete payload['RuleListName'] };
    //@ts-ignore
    if (payload.Priority) { delete payload['Priority'] };

    return httpClient(resourceUrl, {
        method: 'PUT',
        body: JSON.stringify(request),
    })
        .then((response) => {
            let ret: DataTypes.IFwaasApiResponse = {};
            let responseObj = response.json as DataModels.UpdateSecurityRuleResponse;
            if (isSuccess(responseObj)) {
                ret.data = new DataTypes.Rule().fromJSON(responseObj.Response?.RuleEntry);
            } else {
                ret.error = buildError(response, "");
            }
            return ret;
        })
        .catch((err) => {
            return buildError(null, err);
        });
}

async function deleteRule(request: DataModels.DeleteSecurityRuleRequest): Promise<DataTypes.IFwaasApiResponse> {
    let resourceUrl = getResourceURL(EndPoints.RESOURCE.RULES).replace('{ruleStackName}',
        request?.RuleStackName).replace('{ruleListName}', request?.RuleListName) + '/priorities/' + request.Priority;
    return httpClient(resourceUrl, {
        method: 'DELETE'
    })
        .then((response) => {
            let ret: DataTypes.IFwaasApiResponse = {};
            let responseObj = response.json as DataModels.DeleteSecurityRuleResponse;
            if (isSuccess(responseObj)) {
                ret.data = responseObj.Response?.RuleEntry as DataTypes.Rule;
            } else {
                ret.error = buildError(response, "");
            }
            return ret;
        })
        .catch((err) => {
            return buildError(null, err);
        });
}

async function describeRule(request: DataModels.DescribeSecurityRuleRequest): Promise<DataTypes.IFwaasApiResponse> {
    let resourceUrl = getResourceURL(EndPoints.RESOURCE.RULES);
    // resourceUrl = resourceUrl.replace('{ruleStackName}', request.RuleStackName) + '/' + request.RuleListName;
    resourceUrl = resourceUrl.replace('{ruleStackName}', request.RuleStackName).replace('{ruleListName}', request.RuleListName) + '/priorities/' + request.Priority; // CHECK LocalRule for actual rule name
    return httpClient(resourceUrl)
        .then((response) => {
            let ret: DataTypes.IFwaasApiResponse = {};
            let responseObj = response.json as DataModels.DescribeSecurityRuleResponse;
            if (isSuccess(responseObj)) {
                ret.data = responseObj.Response as DataTypes.Rule;
                // CHECK if need to massage data here∂ ---^
            } else {
                ret.error = buildError(response, "");
            }
            return ret;
        })
        .catch((err) => {
            return buildError(null, err);
        });
}


/**
 * CRUD operations for resource 'categories'
 */
async function listCategories(request: DataModels.ListCustomURLCategoriesRequest): Promise<DataTypes.IFwaasApiResponse> {
    let resourceUrl = getResourceURL(EndPoints.RESOURCE.CATEGORY).replace('{ruleStackName}', request?.RuleStackName);
    return httpClient(resourceUrl)
        .then(async (response: any) => {
            let responseObj = response.json as DataModels.ListCustomURLCategoriesResponse;
            let ret: DataTypes.IFwaasApiResponse = {};
            if (isSuccess(responseObj)) {
                ret.data = responseObj.Response?.CategoriesCandidate as string[];
            } else {
                ret.error = buildError(response, "");
            }

            return ret;
        })
        .catch((err) => {
            return buildError(null, err);
        });
}

async function listCategoriesDetails(request: DataModels.ListCustomURLCategoriesRequest): Promise<DataTypes.IFwaasApiResponse> {
    let resourceUrl = getResourceURL(EndPoints.RESOURCE.CATEGORY).replace('{ruleStackName}', request?.RuleStackName);
    if (request && request.NextToken) {
        resourceUrl = resourceUrl + `?maxresults=25&describe=true&nexttoken=${request?.NextToken || undefined}`;
    } else {
        resourceUrl = resourceUrl + `?maxresults=25&describe=true`;
    }
    const controller = new AbortController();
    pushController('category', controller);
    return httpClient(resourceUrl)
        .then(async (response: any) => {
            let ret: DataTypes.IFwaasApiResponse = {};
            let responseObj = response.json as DataModels.ListCustomURLCategoriesResponse;
            let categoryLists: DataTypes.CustomURLCategory[] = [];
            let index: number = Math.floor(100000 + Math.random() * 900000);
            if (isSuccess(responseObj)) {
                responseObj.Response?.CategoriesCandidateDescribe?.map((r: DataModels.CustomURLCategoryDescribe) => {
                    let category: any = r;
                    category.id = index++;
                    category.URLCategoryCandidate = category.CustomURLCategory;
                    delete category.CustomURLCategory;
                    categoryLists.push(category);
                });
                ret.data = categoryLists;
            } else {
                ret.error = buildError(response, "");
            }
            ret.nextToken = responseObj.Response?.NextToken;
            return ret;
        })
        .catch((err) => {
            return buildError(null, err, { noToast: controller.signal.aborted });
        });
}

async function createCategories(request: DataModels.CreateCustomURLCategoryRequest): Promise<DataTypes.IFwaasApiResponse> {
    let resourceUrl = getResourceURL(EndPoints.RESOURCE.CATEGORY).replace('{ruleStackName}', request?.RuleStackName);
    const { RuleStackName, ...payload } = request;
    return httpClient(resourceUrl,
        {
            method: 'POST',
            body: JSON.stringify(payload),
        })
        .then((response) => {
            let ret: DataTypes.IFwaasApiResponse = {};
            let responseObj = response.json as DataModels.CreateCustomURLCategoryResponse;
            if (isSuccess(responseObj)) {
                ret.data = new DataTypes.CustomURLCategory().fromJSON(responseObj.Response);
            } else {
                ret.error = buildError(response, "");
            }
            return ret;
        })
        .catch((err) => {
            return buildError(null, err);
        });
}

async function updateCategories(request: DataModels.UpdateCustomURLCategoryRequest): Promise<DataTypes.IFwaasApiResponse> {
    let resourceUrl = getResourceURL(EndPoints.RESOURCE.CATEGORY).replace('{ruleStackName}', request?.RuleStackName) + "/" + request.Name;
    const { Name, RuleStackName, ...payload } = request;
    return httpClient(resourceUrl, {
        method: 'PUT',
        body: JSON.stringify(payload),
    })
        .then((response) => {
            let ret: DataTypes.IFwaasApiResponse = {};
            let responseObj = response.json as DataModels.UpdateCustomURLCategoryResponse;
            if (isSuccess(responseObj)) {
                ret.data = responseObj.Response?.CustomURLEntry as DataTypes.CustomURLCategory;
                ret.data.id = Math.floor(Math.random() * 50000);
            } else {
                ret.error = buildError(response, "");
            }
            return ret;
        })
        .catch((err) => {
            return buildError(null, err);
        });
}

async function deleteCategories(request: DataModels.DeleteCustomURLCategoryRequest): Promise<DataTypes.IFwaasApiResponse> {
    let resourceUrl = getResourceURL(EndPoints.RESOURCE.CATEGORY).replace('{ruleStackName}', request?.RuleStackName) + "/" + request.Name;
    return httpClient(resourceUrl, {
        method: 'DELETE'
    })
        .then((response) => {
            let ret: DataTypes.IFwaasApiResponse = {};
            let responseObj = response.json as DataModels.DeleteCustomURLCategoryResponse;
            if (isSuccess(responseObj)) {
                ret.data = responseObj.Response?.CustomURLEntry as DataTypes.CustomURLCategory;
            } else {
                ret.error = buildError(response, "");
            }
            return ret;
        })
        .catch((err) => {
            return buildError(null, err);
        });
}

async function describeCategories(request: DataModels.DescribeCustomURLCategoryRequest): Promise<DataTypes.IFwaasApiResponse> {
    let resourceUrl = getResourceURL(EndPoints.RESOURCE.CATEGORY).replace('{ruleStackName}', request?.RuleStackName) + '/' + request.Name;
    return httpClient(resourceUrl) // CHECK correct categories url call
        .then((response) => {
            let ret: DataTypes.IFwaasApiResponse = {};
            let responseObj = response.json as DataModels.DescribeCustomURLCategoryResponse;
            if (isSuccess(responseObj)) {
                ret.data = responseObj.Response as DataTypes.CustomURLCategory;
            } else {
                ret.error = buildError(response, "");
            }
            return ret;
        })
        .catch((err) => {
            return buildError(null, err);
        });
}


/**
 * CRUD operations for resource 'fqdnlists'
 */
async function createFQDNList(request: DataModels.CreateFqdnListRequest): Promise<DataTypes.IFwaasApiResponse> {
    let resourceUrl = getResourceURL(EndPoints.RESOURCE.FQDNLIST).replace('{ruleStackName}', request?.RuleStackName)

    interface FqdnPayloadInterface extends Omit<DataModels.CreateFqdnListRequest, 'RuleStackName'> { }
    let payload = request as FqdnPayloadInterface;

    //@ts-ignore
    if (payload.RuleStackName) { delete payload['RuleStackName'] };

    return httpClient(resourceUrl,
        {
            method: 'POST',
            body: JSON.stringify(request),
        })
        .then((response) => {
            let ret: DataTypes.IFwaasApiResponse = {};
            let responseObj = response.json as DataModels.CreateFqdnListResponse;
            if (isSuccess(responseObj)) {
                ret.data = new DataTypes.FqdnListDetails().fromJSON(responseObj.Response);
            } else {
                ret.error = buildError(response, "");
            }
            return ret;
        })
        .catch((err) => {
            return buildError(null, err);
        });
}

async function updateFQDNList(request: DataModels.UpdateFqdnListRequest): Promise<DataTypes.IFwaasApiResponse> {
    let url = getResourceURL(EndPoints.RESOURCE.FQDNLIST).replace('{ruleStackName}', request.RuleStackName);
    const { Name, RuleStackName, ...payload } = request;
    return httpClient(url + "/" + request.Name, {
        method: 'PUT',
        body: JSON.stringify(payload),
    })
        .then((response) => {
            let ret: DataTypes.IFwaasApiResponse = {};
            let responseObj = response.json as DataModels.UpdateFqdnListResponse;
            if (isSuccess(responseObj)) {
                ret.data = responseObj.Response?.FqdnListEntry as DataTypes.FqdnListDetails;
                ret.data.id = Math.floor(Math.random() * 50000);
            } else {
                ret.error = buildError(response, "");
            }
            return ret;
        })
        .catch((err) => {
            return buildError(null, err);
        });
}

async function deleteFQDNList(request: DataModels.DeleteFqdnListRequest): Promise<DataTypes.IFwaasApiResponse> {
    let resourceUrl = getResourceURL(EndPoints.RESOURCE.FQDNLIST).replace('{ruleStackName}', request?.RuleStackName) + "/" + request.Name;
    return httpClient(resourceUrl, {
        method: 'DELETE'
    })
        .then((response) => {
            let ret: DataTypes.IFwaasApiResponse = {};
            let responseObj = response.json as DataModels.DeleteFqdnListResponse;
            if (isSuccess(responseObj)) {
                ret.data = responseObj.Response?.FqdnListEntry as DataTypes.FqdnListDetails;
            } else {
                ret.error = buildError(response, "");
            }
            return ret;
        })
        .catch((err) => {
            return buildError(null, err);
        });
}

async function listFQDNListDetail(request: DataModels.ListFqdnListRequest): Promise<DataTypes.IFwaasApiResponse> {
    let resourceUrl = getResourceURL(EndPoints.RESOURCE.FQDNLIST).replace('{ruleStackName}', request?.RuleStackName);
    if (request && request.NextToken) {
        resourceUrl = resourceUrl + `?maxresults=25&describe=true&nexttoken=${request?.NextToken || undefined}`;
    } else {
        resourceUrl = resourceUrl + `?maxresults=25&describe=true`;
    }
    const controller = new AbortController();
    pushController('fqdn', controller);
    return httpClient(resourceUrl, { signal: controller.signal })
        .then(async (response: any) => {
            let ret: DataTypes.IFwaasApiResponse = {};
            let responseObj = response.json as DataModels.ListFqdnListResponse;
            let fqdnLists: DataTypes.FqdnListDetails[] = [];
            let index: number = Math.floor(100000 + Math.random() * 900000);
            if (isSuccess(responseObj)) {
                responseObj.Response?.FqdnListCandidateDescribe?.map((r: DataModels.FqdnListDescribe) => {
                    let fqdnList: any = r;
                    fqdnList.id = index++;
                    fqdnList.FqdnListCandidate = { ...fqdnList.FqdnList };
                    delete fqdnList.FqdnList;
                    fqdnLists.push(fqdnList);
                })
                ret.data = fqdnLists;
            } else {
                ret.error = buildError(response, "");
            }
            ret.nextToken = responseObj.Response?.NextToken;
            return ret;
        })
        .catch((err) => {
            return buildError(null, err, { noToast: controller.signal.aborted });
        });
};

async function describeFQDNList(request: DataModels.DescribeFqdnListRequest): Promise<DataTypes.IFwaasApiResponse> {
    let resourceUrl = getResourceURL(EndPoints.RESOURCE.FQDNLIST).replace('{ruleStackName}', request?.RuleStackName) + "/" + request.Name;
    return httpClient(resourceUrl)
        .then((response) => {
            let ret: DataTypes.IFwaasApiResponse = {};
            let responseObj = response.json as DataModels.DescribeFqdnListResponse;
            if (isSuccess(responseObj)) {
                ret.data = responseObj.Response as DataTypes.FqdnListDetails;
            } else {
                ret.error = buildError(response, "");
            }
            return ret;
        })
        .catch((err) => {
            return buildError(null, err);
        });
}

/**
 * CRUD operations for resource 'feeds'
 */
async function createFeed(request: DataModels.CreateIntelligentFeedRequest): Promise<DataTypes.IFwaasApiResponse> {
    let resourceUrl = getResourceURL(EndPoints.RESOURCE.FEED).replace('{ruleStackName}', request?.RuleStackName);
    const { RuleStackName, ...payload } = request;
    return httpClient(resourceUrl,
        {
            method: 'POST',
            body: JSON.stringify(payload),
        })
        .then((response) => {
            let ret: DataTypes.IFwaasApiResponse = {};
            let responseObj = response.json as DataModels.CreateIntelligentFeedResponse;
            if (isSuccess(responseObj)) {
                ret.data = new DataTypes.FeedInfo().fromJSON(responseObj.Response);
            } else {
                ret.error = buildError(response, "");
            }
            return ret;
        })
        .catch((err) => {
            return buildError(null, err);
        });
}

async function updateFeed(request: DataModels.UpdateIntelligentFeedRequest): Promise<DataTypes.IFwaasApiResponse> {
    let url = getResourceURL(EndPoints.RESOURCE.FEED).replace('{ruleStackName}', request?.RuleStackName);
    const { Name, RuleStackName, ...payload } = request;
    return httpClient(url + "/" + request.Name,
        {
            method: 'PUT',
            body: JSON.stringify(payload),
        })
        .then((response) => {
            let ret: DataTypes.IFwaasApiResponse = {};
            let responseObj = response.json as DataModels.UpdateIntelligentFeedResponse;
            if (isSuccess(responseObj)) {
                ret.data = responseObj.Response?.FeedEntry as DataTypes.FeedInfo;
                ret.data.id = Math.floor(Math.random() * 50000);
            } else {
                ret.error = buildError(response, "");
            }
            return ret;
        })
        .catch((err) => {
            return buildError(null, err);
        });
}

async function deleteFeed(request: DataModels.DeleteIntelligentFeedRequest): Promise<DataTypes.IFwaasApiResponse> {
    let resourceUrl = getResourceURL(EndPoints.RESOURCE.FEED).replace('{ruleStackName}', request?.RuleStackName) + "/" + request.Name;
    return httpClient(resourceUrl,
        {
            method: 'DELETE'
        })
        .then((response) => {
            let ret: DataTypes.IFwaasApiResponse = {};
            let responseObj = response.json as DataModels.DeleteIntelligentFeedResponse;
            if (isSuccess(responseObj)) {
                ret.data = responseObj.Response?.FeedEntry as DataTypes.FeedInfo;
            } else {
                ret.error = buildError(response, "");
            }
            return ret;
        })
        .catch((err) => {
            return buildError(null, err);
        });
}

async function listFeed(request: DataModels.ListIntelligentFeedRequest): Promise<DataTypes.IFwaasApiResponse> {
    return httpClient(getResourceURL(EndPoints.RESOURCE.FEED)
        + "/" + request.RuleStackName + "/" + EndPoints.RESOURCE.FEED)
        .then((response) => {
            let ret: DataTypes.IFwaasApiResponse = {};
            let responseObj = response.json as DataModels.ListIntelligentFeedResponse;
            if (isSuccess(responseObj)) {
                ret.data = responseObj.Response?.FeedCandidate as string[];
            } else {
                ret.error = buildError(response, "");
            }
            return ret;
        })
        .catch((err) => {
            return buildError(null, err);
        });
}

async function listFeedDetail(request: DataModels.ListIntelligentFeedRequest): Promise<DataTypes.IFwaasApiResponse> {
    let resourceUrl = getResourceURL(EndPoints.RESOURCE.FEED).replace('{ruleStackName}', request?.RuleStackName);
    if (request && request.NextToken) {
        resourceUrl = resourceUrl + `?maxresults=25&nexttoken=${request?.NextToken || undefined}`;
    } else {
        resourceUrl = resourceUrl + `?maxresults=25`;
    }
    const controller = new AbortController();
    pushController('feed', controller);
    return httpClient(resourceUrl, { signal: controller.signal })
        .then(async (response: any) => {
            let ret: DataTypes.IFwaasApiResponse = {};
            let feeds: DataTypes.FeedInfo[] = [];
            let responseObj = response.json as DataModels.ListIntelligentFeedResponse;
            if (isSuccess(responseObj)) {
                let index: number = Math.floor(100000 + Math.random() * 900000);
                let feedNames = responseObj.Response?.FeedCandidate as string[];
                if (feedNames) {
                    await Promise.all(
                        feedNames.map(async (feedName: string) => describeFeed(
                            {
                                RuleStackName: request.RuleStackName,
                                Name: feedName,
                                Candidate: true
                            }))
                    ).then((response2) => {
                        response2.map((r: DataTypes.IFwaasApiResponse) => {
                            if (r.data) {
                                let feed = r.data as DataTypes.FeedInfo;
                                feed.id = index++;
                                feeds.push(feed);
                            }
                            return r;
                        })
                    })

                    ret.data = feeds;
                }
            } else {
                ret.error = buildError(response, "");
            }
            ret.nextToken = responseObj.Response?.NextToken;
            return ret;
        })
        .catch((err) => {
            return buildError(null, err, { noToast: controller.signal.aborted });
        });
}

async function describeFeed(request: DataModels.DescribeIntelligentFeedRequest): Promise<DataTypes.IFwaasApiResponse> {
    let resourceUrl = getResourceURL(EndPoints.RESOURCE.FEED).replace('{ruleStackName}', request?.RuleStackName) + "/" + request.Name;
    return httpClient(resourceUrl)
        .then((response) => {
            let ret: DataTypes.IFwaasApiResponse = {};
            let responseObj = response.json as DataModels.DescribeIntelligentFeedResponse;
            if (isSuccess(responseObj)) {
                ret.data = new DataTypes.FeedInfo().fromJSON(responseObj.Response);
            } else {
                ret.error = buildError(response, "");
            }
            return ret;
        })
        .catch((err) => {
            return buildError(null, err);
        });
}

/**
 * CRUD operations for resource 'prefixlists'
 */
async function createPrefixList(request: DataModels.CreatePrefixListRequest): Promise<DataTypes.IFwaasApiResponse> {
    let resourceUrl = getResourceURL(EndPoints.RESOURCE.PREFIXLIST).replace('{ruleStackName}', request?.RuleStackName)

    interface PayloadInterface extends Omit<DataModels.CreatePrefixListRequest, 'RuleStackName'> { }
    let payload = request as PayloadInterface;

    //@ts-ignore
    if (payload.RuleStackName) { delete payload['RuleStackName'] };

    return httpClient(resourceUrl,
        {
            method: 'POST',
            body: JSON.stringify(request),
        })
        .then((response) => {
            let ret: DataTypes.IFwaasApiResponse = {};
            let responseObj = response.json as DataModels.CreatePrefixListResponse;
            if (isSuccess(responseObj)) {
                ret.data = new DataTypes.PrefixListInfo().fromJSON(responseObj.Response);
            } else {
                ret.error = buildError(response, "");
            }
            return ret;
        })
        .catch((err) => {
            return buildError(null, err);
        });
}

async function updatePrefixList(request: DataModels.UpdatePrefixListRequest): Promise<DataTypes.IFwaasApiResponse> {
    let url = getResourceURL(EndPoints.RESOURCE.PREFIXLIST).replace('{ruleStackName}', request?.RuleStackName);
    const { Name, RuleStackName, ...payload } = request;
    return httpClient(url + "/" + request.Name,
        {
            method: 'PUT',
            body: JSON.stringify(payload),
        })
        .then((response) => {
            let ret: DataTypes.IFwaasApiResponse = {};
            let responseObj = response.json as DataModels.UpdatePrefixListResponse;
            if (isSuccess(responseObj)) {
                ret.data = responseObj.Response?.PrefixListEntry as DataModels.PrefixListDetails;
                ret.data.id = Math.floor(Math.random() * 50000);
            } else {
                ret.error = buildError(response, "");
            }
            return ret;
        })
        .catch((err) => {
            return buildError(null, err);
        });
}

async function deletePrefixList(request: DataModels.DeletePrefixListRequest): Promise<DataTypes.IFwaasApiResponse> {
    let resourceUrl = getResourceURL(EndPoints.RESOURCE.PREFIXLIST).replace('{ruleStackName}', request?.RuleStackName);
    return httpClient(resourceUrl + "/" + request.Name,
        {
            method: 'DELETE'
        })
        .then((response) => {
            let ret: DataTypes.IFwaasApiResponse = {};
            let responseObj = response.json as DataModels.DeletePrefixListResponse;
            if (isSuccess(responseObj)) {
                ret.data = responseObj.Response?.PrefixListEntry as DataTypes.PrefixListInfo;
            } else {
                ret.error = buildError(response, "");
            }
            return ret;
        })
        .catch((err) => {
            return buildError(null, err);
        });
}

async function listPrefixListDetail(request: DataModels.ListPrefixListRequest): Promise<DataTypes.IFwaasApiResponse> {
    let resourceUrl = getResourceURL(EndPoints.RESOURCE.PREFIXLIST).replace('{ruleStackName}', request?.RuleStackName);
    if (request && request.NextToken) {
        resourceUrl = resourceUrl + `?maxresults=25&describe=true&nexttoken=${request?.NextToken || undefined}`;
    } else {
        resourceUrl = resourceUrl + `?maxresults=25&describe=true`;
    }
    const controller = new AbortController();
    pushController('prefix', controller);
    return httpClient(resourceUrl, { signal: controller.signal })
        .then(async (response: any) => {
            let ret: DataTypes.IFwaasApiResponse = {};
            let prefixLists: DataTypes.PrefixListInfo[] = [];
            let responseObj = response.json as DataModels.ListPrefixListResponse;
            let index = 0;
            if (isSuccess(responseObj)) {
                responseObj.Response?.PrefixListCandidateDescribe?.map((r: DataModels.PrefixListDescribe) => {
                    let prefix: any = r;
                    prefix.id = index++;
                    prefix.PrefixListCandidate = { ...r.PrefixList };
                    delete r.PrefixList;
                    prefixLists.push(prefix);
                })
                ret.data = prefixLists;
            } else {
                ret.error = buildError(response, "");
            }
            ret.nextToken = responseObj.Response?.NextToken;
            return ret;
        })
        .catch((err) => {
            return buildError(null, err, { noToast: controller.signal.aborted });
        });
}

async function listPrefixList(request: DataModels.ListPrefixListRequest): Promise<DataTypes.IFwaasApiResponse> {
    let resourceUrl = getResourceURL(EndPoints.RESOURCE.PREFIXLIST).replace('{ruleStackName}', request?.RuleStackName);
    return httpClient(resourceUrl)
        .then((response) => {
            let responseObj = response.json as DataModels.ListPrefixListResponse;

            let ret: DataTypes.IFwaasApiResponse = {};
            if (isSuccess(responseObj)) {
                ret.data = responseObj.Response?.PrefixListCandidate as string[];
            } else {
                ret.error = buildError(response, "");
            }

            return ret;
        })
        .catch((err) => {
            return buildError(null, err);
        });
}

async function listFqdnList(request: DataModels.ListFqdnListRequest): Promise<DataTypes.IFwaasApiResponse> {
    let resourceUrl = getResourceURL(EndPoints.RESOURCE.FQDNLIST).replace('{ruleStackName}', request?.RuleStackName);
    return httpClient(resourceUrl)
        .then((response) => {
            let responseObj = response.json as DataModels.ListFqdnListResponse;

            let ret: DataTypes.IFwaasApiResponse = {};
            if (isSuccess(responseObj)) {
                ret.data = responseObj.Response?.FqdnListCandidate as string[];
            } else {
                ret.error = buildError(response, "");
            }

            return ret;
        })
        .catch((err) => {
            return buildError(null, err);
        });
}

async function listFeedList(request: DataModels.ListIntelligentFeedRequest): Promise<DataTypes.IFwaasApiResponse> {
    let resourceUrl = getResourceURL(EndPoints.RESOURCE.FEED).replace('{ruleStackName}', request?.RuleStackName);
    if (request.Type) {
        resourceUrl += "?type=" + request.Type;
    }
    return httpClient(resourceUrl)
        .then((response) => {
            let responseObj = response.json as DataModels.ListIntelligentFeedResponse;

            let ret: DataTypes.IFwaasApiResponse = {};
            if (isSuccess(responseObj)) {
                ret.data = responseObj.Response?.FeedCandidate as string[];
            } else {
                ret.error = buildError(response, "");
            }

            return ret;
        })
        .catch((err) => {
            return buildError(null, err);
        });
}

async function describePrefixList(request: DataModels.DescribePrefixListRequest): Promise<DataTypes.IFwaasApiResponse> {
    let resourceUrl = getResourceURL(EndPoints.RESOURCE.PREFIXLIST).replace('{ruleStackName}', request?.RuleStackName) + "/" + request.Name;
    return httpClient(resourceUrl)
        .then((response) => {
            let ret: DataTypes.IFwaasApiResponse = {};
            let responseObj = response.json as DataModels.DescribePrefixListResponse;
            if (isSuccess(responseObj)) {
                ret.data = new DataTypes.PrefixListInfo().fromJSON(responseObj.Response as DataTypes.PrefixListInfo);
            } else {
                ret.error = buildError(response, "");
            }
            return ret;
        })
        .catch((err) => {
            return buildError(null, err);
        });
}

/**
 * CRUD operations for resource 'certificate'
 */
async function createCertificate(request: DataModels.CreateCertificateObjectRequest): Promise<DataTypes.IFwaasApiResponse> {
    let resourceUrl = getResourceURL(EndPoints.RESOURCE.CERTIFICATE).replace('{ruleStackName}', request?.RuleStackName)

    interface PayloadInterface extends Omit<DataModels.CreateCertificateObjectRequest, 'RuleStackName'> { }
    let payload = request as PayloadInterface;

    //@ts-ignore
    if (payload.RuleStackName) { delete payload['RuleStackName'] };

    return httpClient(resourceUrl,
        {
            method: 'POST',
            body: JSON.stringify(request),
        })
        .then((response) => {
            let ret: DataTypes.IFwaasApiResponse = {};
            let responseObj = response.json as DataModels.CreateCertificateObjectResponse;
            if (isSuccess(responseObj)) {
                ret.data = new DataTypes.CertificateInfo().fromJSON(responseObj.Response);
            } else {
                ret.error = buildError(response, "");
            }
            return ret;
        })
        .catch((err) => {
            return buildError(null, err);
        });
}

async function updateCertificate(request: DataModels.UpdateCertificateObjectRequest): Promise<DataTypes.IFwaasApiResponse> {
    let url = getResourceURL(EndPoints.RESOURCE.CERTIFICATE).replace('{ruleStackName}', request?.RuleStackName) + "/" + request.Name;
    const { Name, RuleStackName, ...payload } = request;
    return httpClient(url,
        {
            method: 'PUT',
            body: JSON.stringify(payload),
        })
        .then((response) => {
            let ret: DataTypes.IFwaasApiResponse = {};
            let responseObj = response.json as DataModels.UpdateCertificateObjectResponse;
            if (isSuccess(responseObj)) {
                ret.data = responseObj.Response?.CertificateObjectEntry as DataModels.CertificateDetails;
                ret.data.id = Math.floor(Math.random() * 50000);
            } else {
                ret.error = buildError(response, "");
            }
            return ret;
        })
        .catch((err) => {
            return buildError(null, err);
        });
}

async function deleteCertificate(request: DataModels.DeletePrefixListRequest): Promise<DataTypes.IFwaasApiResponse> {
    let resourceUrl = getResourceURL(EndPoints.RESOURCE.CERTIFICATE).replace('{ruleStackName}', request?.RuleStackName);
    return httpClient(resourceUrl + "/" + request.Name,
        {
            method: 'DELETE'
        })
        .then((response) => {
            let ret: DataTypes.IFwaasApiResponse = {};
            let responseObj = response.json as DataModels.DeletePrefixListResponse;
            if (isSuccess(responseObj)) {
                ret.data = responseObj.Response?.PrefixListEntry as DataTypes.PrefixListInfo;
            } else {
                ret.error = buildError(response, "");
            }
            return ret;
        })
        .catch((err) => {
            return buildError(null, err);
        });
}
async function listCertificate(request: DataModels.ListCertificateObjectRequest): Promise<DataTypes.IFwaasApiResponse> {
    let resourceUrl = getResourceURL(EndPoints.RESOURCE.CERTIFICATE).replace('{ruleStackName}', request?.RuleStackName);
    if ('selfsigned' in request) {
        // @ts-ignore
        resourceUrl += `?selfsigned=${request?.selfsigned}`;
    }
    return httpClient(resourceUrl)
        .then(async (response) => {
            let responseObj = response.json as DataModels.ListCertificateObjectResponse;
            let ret: DataTypes.IFwaasApiResponse = {};
            if (isSuccess(responseObj)) {
                ret.data = responseObj.Response?.CertificateObjectCandidate as string[];
            } else {
                ret.error = buildError(response, "");
            }

            return ret;
        })
        .catch((err) => {
            return { error: buildError(null, err) }
        });
}

async function listCertificateDetail(request: DataModels.ListCertificateObjectRequest): Promise<DataTypes.IFwaasApiResponse> {
    let resourceUrl = getResourceURL(EndPoints.RESOURCE.CERTIFICATE).replace('{ruleStackName}', request?.RuleStackName);
    resourceUrl += '?maxresults=25&describe=true'
    if (request && request.NextToken) {
        resourceUrl = resourceUrl + `&nexttoken=${request?.NextToken || undefined}`;
    }
    const controller = new AbortController();
    pushController('certificate', controller);
    return httpClient(resourceUrl, { signal: controller.signal })
        .then(async (response: any) => {
            let ret: DataTypes.IFwaasApiResponse = {};
            let certificateLists: DataTypes.CertificateInfo[] = [];
            let responseObj = response.json as DataModels.ListCertificateObjectResponse;
            let index: number = Math.floor(100000 + Math.random() * 900000);
            if (isSuccess(responseObj)) {
                responseObj.Response?.CertificateObjectCandidateDescribe?.map((r: DataModels.CertificateObjectDescribe) => {
                    let certificate: any = r;
                    certificate.id = index++;
                    certificate.CertificateObjectCandidate = { ...r.CertificateObject };
                    delete r.CertificateObject;
                    certificateLists.push(certificate);
                });
                ret.data = certificateLists;
            } else {
                ret.error = buildError(response, "");
            }
            ret.nextToken = responseObj.Response?.NextToken;
            return ret;
        })
        .catch((err) => {
            return buildError(null, err, { noToast: controller.signal.aborted });
        });
}

async function describeCertificate(request: DataModels.DescribeCertificateObjectRequest): Promise<DataTypes.IFwaasApiResponse> {
    let resourceUrl = getResourceURL(EndPoints.RESOURCE.CERTIFICATE).replace('{ruleStackName}', request?.RuleStackName) + "/" + request.Name;
    return httpClient(resourceUrl)
        .then((response) => {
            let ret: DataTypes.IFwaasApiResponse = {};
            let responseObj = response.json as DataModels.DescribeCertificateObjectResponse;
            if (isSuccess(responseObj)) {
                ret.data = responseObj.Response as DataTypes.CertificateInfo;
            } else {
                ret.error = buildError(response, "");
            }
            return ret;
        })
        .catch((err) => {
            return buildError(null, err);
        });
}

async function listXAccountRoles(request: DataModels.ListXAccountRolesRequest): Promise<DataTypes.IFwaasApiResponse> {
    return httpClient(getResourceURL(EndPoints.RESOURCE.XACCOUNT_ROLES))
        .then((response) => {
            let responseObj = response.json as DataModels.ListXAccountRolesResponse;

            let ret: DataTypes.IFwaasApiResponse = {};
            if (isSuccess(responseObj)) {
                ret.data = responseObj.Response as string[];
            } else {
                ret.error = buildError(response, "");
            }

            return ret;
        })
        .catch((err) => {
            return buildError(null, err);
        });
}

async function listXAccountsDetails(): Promise<DataTypes.IFwaasApiResponse> {
    const controller = new AbortController();
    pushController('xaccountroles', controller);
    const url = getResourceURL(EndPoints.RESOURCE.XACCOUNT_ROLES) + '?describe=true';

    return httpClient(url, { signal: controller.signal })
        .then(async (response: any) => {
            let responseObj = response.json as DataModels.ListXAccountRolesResponse;
            let ret: DataTypes.IFwaasApiResponse = {};

            if (isSuccess(responseObj)) {
                ret.data = responseObj.Response;
            } else {
                ret.error = buildError(response, "");
            }
            return ret;
        })
        .catch((err) => {
            return buildError(null, err, { noToast: controller.signal.aborted })
        });
}

async function listVPCDetails(request: DataModels.ListVpcConfigRequest): Promise<DataTypes.IFwaasApiResponse> {
    const controller = new AbortController();
    // pushController('vpcs', controller);
    let url = getResourceURL(EndPoints.RESOURCE.VPCS);

    return httpClient(url)
        .then(async (response: any) => {
            let responseObj = response.json as DataModels.ListVpcConfigResponse;
            let ret: DataTypes.IFwaasApiResponse = {};
            let accounts: DataTypes.Account[] = [];

            if (isSuccess(responseObj)) {
                let index: number = Math.floor(100000 + Math.random() * 900000);
                //@ts-ignore
                let vpcs = responseObj.Response?.VpcList as string[];
                ret.data = vpcs;
                ret.timestamp = responseObj.Response?.LastUpdatedTimestamp;
            } else {
                ret.error = buildError(response, "");
            }
            return ret;
        })
        .catch((err) => {
            return buildError(null, err, { noToast: controller.signal.aborted })
        });
}

async function listTagsDetails(request: any): Promise<DataTypes.IFwaasApiResponse> {
    const controller = new AbortController();
    // pushController('vpcs', controller);
    let resourceUrl = getResourceURL(EndPoints.RESOURCE.VPCTAGS);

    return httpClient(resourceUrl)
        .then(async (response: any) => {
            let responseObj = response.json as DataModels.TagAccountVpcEntry;
            let ret: DataTypes.IFwaasApiResponse = {};
            let accounts: DataTypes.Account[] = [];

            if (isSuccess(responseObj)) {
                let index: number = Math.floor(100000 + Math.random() * 900000);
                //@ts-ignore
                let tags = responseObj.Response as string[];
                ret.data = tags;
                // ret.data = tags;
            } else {
                ret.error = buildError(response, "");
            }
            return ret;
        })
        .catch((err) => {
            return buildError(null, err, { noToast: controller.signal.aborted })
        });
}

async function listTagIPsDetails(request: any): Promise<DataTypes.IFwaasApiResponse> {
    const controller = new AbortController();
    // pushController('vpcs', controller);
    let resourceUrl = getResourceURL(EndPoints.RESOURCE.VPCTAGIPS)
    // .replace('{account}', request?.accountId)
    // .replace('{vpc}', request?.vpcId)
    .replace('{name}', encodeURIComponent(request?.tagName));
    if (request?.accountId !== undefined) {
        resourceUrl += `?accountid=${request?.accountId}`;
    }
    if (request?.vpcId !== undefined) {
        resourceUrl += `&vpcid=${request?.vpcId}`;
    }

    return httpClient(resourceUrl)
        .then(async (response: any) => {
            let responseObj = response.json as any;
            let ret: DataTypes.IFwaasApiResponse = {};
            let accounts: DataTypes.Account[] = [];

            if (isSuccess(responseObj)) {
                let index: number = Math.floor(100000 + Math.random() * 900000);
                //@ts-ignore
                let prefixes = responseObj.Response as string[];
                ret.data = prefixes;
                // ret.data = tags;
            } else {
                ret.error = buildError(response, "");
            }
            return ret;
        })
        .catch((err) => {
            return buildError(null, err, { noToast: controller.signal.aborted })
        });
}

async function listPrefixDetails(request: any): Promise<DataTypes.IFwaasApiResponse> {
    const controller = new AbortController();
    let resourceUrl = getResourceURL(EndPoints.RESOURCE.VPCPREFIXTAGS)
    .replace('{name}', encodeURIComponent(request?.ipPrefix));

    if (request?.accountId !== undefined) {
        resourceUrl += `?accountid=${request?.accountId}`;
    }
    if (request?.vpcId !== undefined) {
        resourceUrl += `&vpcid=${request?.vpcId}`;
    }

    return httpClient(resourceUrl)
        .then(async (response: any) => {
            let responseObj = response.json as any;
            let ret: DataTypes.IFwaasApiResponse = {};
            let accounts: DataTypes.Account[] = [];

            if (isSuccess(responseObj)) {
                let index: number = Math.floor(100000 + Math.random() * 900000);
                //@ts-ignore
                let prefixTags = responseObj.Response as string[];
                ret.data = prefixTags;
                // ret.data = tags;
            } else {
                ret.error = buildError(response, responseObj);
            }
            return ret;
        })
        .catch((err) => {
            return buildError(null, err, { noToast: controller.signal.aborted })
        });
}

async function listGroupsDetails(request: any): Promise<DataTypes.IFwaasApiResponse> {
    const controller = new AbortController();
    // pushController('vpcs', controller);
    let resourceUrl = getResourceURL(EndPoints.RESOURCE.VPCGROUPS);

    return httpClient(resourceUrl)
        .then(async (response: any) => {
            let responseObj = response.json as any;
            let ret: DataTypes.IFwaasApiResponse = {};
            let accounts: DataTypes.Account[] = [];

            if (isSuccess(responseObj)) {
                let index: number = Math.floor(100000 + Math.random() * 900000);
                //@ts-ignore
                ret.data = responseObj.Response.VpcGroups as string[];
                // ret.data = tags;
            } else {
                ret.error = buildError(response, "");
            }
            return ret;
        })
        .catch((err) => {
            return buildError(null, err, { noToast: controller.signal.aborted })
        });
}

async function describeXAccountRoleArn(request: DataModels.DescribeXAccountRoleArnRequest): Promise<DataTypes.IFwaasApiResponse> {
    let describeAccount = (request.DescribeAccount) ? '?describeaccount=true' : ''
    let vpcId = (request.VpcId) ? `&vpcid=${request.VpcId}` : undefined;
    let resourceUrl = getResourceURL(EndPoints.RESOURCE.XACCOUNT_ROLES) + "/" + request.AccountId + describeAccount;
    if (vpcId) {
        resourceUrl = resourceUrl.concat(vpcId);
    }

    const controller = new AbortController();
    pushController('xaccountroles', controller);

    return httpClient(resourceUrl, { signal: controller.signal })
        .then((response) => {
            let responseObj = response.json as DataModels.DescribeXAccountRoleArnResponse;
            let ret: DataTypes.IFwaasApiResponse = {};
            if (isSuccess(responseObj)) {
                ret.data = new DataTypes.Account().fromJSON(responseObj.Response);
            } else {
                ret.error = buildError(response, "");
            }

            return ret;
        })
        .catch((err) => {
            return buildError(null, err, { noToast: controller.signal.aborted });
        });
}

async function updateXAccountRoleArn(request: any): Promise<DataTypes.IFwaasApiResponse> {
    let url = getResourceURL(EndPoints.RESOURCE.XACCOUNT_ROLES);
    //const { Name, RuleStackName, ...payload } = request;
    return httpClient(url,
        {
            method: 'PUT',
            body: JSON.stringify(request),
        })
        .then((response) => {
            let ret: DataTypes.IFwaasApiResponse = {};
            let responseObj = response.json as any;
            if (isSuccess(responseObj)) {
                ret.data = responseObj.Response;
            } else {
                ret.error = buildError(response, "");
            }
            return ret;
        })
        .catch((err) => {
            return buildError(null, err);
        });
}

/**
 * CRUD operations for resource 'predefinedUrlCategory'
 */

async function listPredefinedUrlCategory(request: DataModels.ListURLPredefinedCategoriesRequest, prevData: any = {}): Promise<DataTypes.IFwaasApiResponse> {
    let resourceUrl = getResourceURL(EndPoints.RESOURCE.PREDEFINED);
    if (request.NextToken) {
        resourceUrl = resourceUrl + `?nexttoken=${request.NextToken}`;
    }
    return httpClient(resourceUrl)
        .then(async (response: any) => {
            let responseObj = response.json as DataModels.ListURLPredefinedCategoriesResponse;
            let ret: DataTypes.IFwaasApiResponse = {};
            if (isSuccess(responseObj)) {
                ret.data = responseObj.Response as any;
                if (!isEmpty(prevData)) {
                    ret.data.CategoriesRunning = [...ret.data.CategoriesRunning, ...prevData?.data?.CategoriesRunning];
                }
            } else {
                ret.error = buildError(response, "");
            }
            if (ret.data.NextToken && ret.data.NextToken !== "None") {
                request.NextToken = ret.data.NextToken;
                return await listPredefinedUrlCategory(request, ret);
            }
            if (ret.data) {
                ret.data = ret.data.CategoriesRunning.map((item: any) => {
                    return item.Name;
                })
            }
            return ret;
        })
        .catch((err) => {
            return buildError(null, err);
        });
}

async function listMergedPredefinedUrlCategoryDetail(request: any): Promise<DataTypes.IFwaasApiResponse> {
    let calls = [];
    //@ts-ignore
    let ruleStackName = request?.RuleStackName;
    if(request?.typeSelected === 'Custom') {
        delete request.typeSelected;
        calls.push(listPredefinedUrlCategoryDetail(request));
        calls.push(listPredefinedUrlCategoriesOverriden(request));
        return Promise.all(calls).then(async response => {
            let ret: DataTypes.IFwaasApiResponse = {};
            //@ts-ignore
            ret.data = response[0].data;
            //@ts-ignore
            let categories = response[1].data?.Response?.CategoriesCandidate || [];
            if (categories) {
                await Promise.all(
                    categories.map(async (Name: any) => describePredefinedUrlCategoriesOverriden({ ruleStackName, Name }))
                ).then((overrideRes: any) => {
                    ret.data.Response.CategoriesRunning = ret.data.Response.CategoriesRunning.map(
                        (category: any) => {
                            let override = overrideRes.filter((cat: any) => cat.Response.Name === category.Name);
                            if (override.length) {
                                return {
                                    Name: override[0].Response.Name,
                                    Action: override[0].Response.URLCategoryCandidate.Action,
                                    Override: true
                                };
                            }
                            return category;
                        });
                })
            }
            return ret;
        }).catch((err) => {
            return buildError(null, err);
        });
    } else {
        delete request.typeSelected;
        calls.push(listPredefinedUrlCategoryDetail(request));
        return Promise.all(calls).then(async response => {
            let ret: DataTypes.IFwaasApiResponse = {};
            //@ts-ignore
            ret.data = response[0].data;
            return ret;
        }).catch((err) => {
            return buildError(null, err);
        });
    }
}

async function listPredefinedUrlCategoryDetail(request: DataModels.ListURLPredefinedCategoriesRequest, prevData: any = {}): Promise<DataTypes.IFwaasApiResponse> {
    let resourceUrl = getResourceURL(EndPoints.RESOURCE.PREDEFINED);
    if (request && request.NextToken) {
        resourceUrl = resourceUrl + `?maxresults=25&nexttoken=${request?.NextToken || undefined}`;
    } else {
        resourceUrl = resourceUrl + `?maxresults=25`;
    }
    return httpClient(resourceUrl)
        .then(async (response: any) => {
            let ret: DataTypes.IFwaasApiResponse = {};
            let responseObj = response.json as DataModels.ListURLPredefinedCategoriesResponse;
            if (isSuccess(responseObj)) {
                ret.data = responseObj as any;
                if (!isEmpty(prevData)) {
                    ret.data.Response.CategoriesRunning = [...prevData?.data?.Response?.CategoriesRunning, ...ret.data.Response.CategoriesRunning];
                }
            } else {
                ret.error = buildError(response, "");
            }
            if (ret.data.Response.NextToken && ret.data.Response.NextToken !== "None") {
                request.NextToken = ret.data.Response.NextToken;
                return await listPredefinedUrlCategoryDetail(request, ret);
            }
            return ret;
        })
        .catch((err) => {
            return buildError(null, err);
        });
}

async function listPredefinedUrlCategoriesOverriden(request: DataModels.ListURLPredefinedCategoriesRequest, prevData: any = {}): Promise<DataTypes.IFwaasApiResponse> {
    //@ts-ignore
    let ruleStackName = request?.RuleStackName;
    let resourceUrl = getResourceURL(EndPoints.RESOURCE.PREDEFINED_OVERRIDE).replace('{ruleStackName}', ruleStackName);
    if (request.NextToken) {
        resourceUrl = resourceUrl + `?nexttoken=${request.NextToken}`;
    }
    return httpClient(resourceUrl)
        .then(async (response: any) => {
            let ret: DataTypes.IFwaasApiResponse = {};
            let responseObj = response.json as DataModels.ListURLCategoriesActionOverrideResponse;
            if (isSuccess(responseObj)) {
                ret.data = responseObj as any;
                if (!isEmpty(prevData)) {
                    ret.data.Response.CategoriesRunning = [...prevData?.data?.Response?.CategoriesRunning, ...ret.data.Response.CategoriesRunning];
                }
            } else {
                ret.error = buildError(response, "");
            }
            if (ret.data.Response.NextToken && ret.data.Response.NextToken !== "None") {
                request.NextToken = ret.data.Response.NextToken;
                return await listPredefinedUrlCategoriesOverriden(request, ret);
            }
            return ret;
        })
        .catch((err) => {
            return buildError(null, err);
        });
}

async function describePredefinedUrlCategoriesOverriden(request: any) {
    let { ruleStackName, Name } = request;
    let overrideUrl = getResourceURL(EndPoints.RESOURCE.PREDEFINED_OVERRIDE).replace('{ruleStackName}', ruleStackName) + '/' + Name;
    return httpClient(overrideUrl).then(res => {
        return res.json as DataModels.DescribeCustomURLCategoryResponse
    });
}

async function updatePredefinedUrlCategory(request: any): Promise<DataTypes.IFwaasApiResponse> {
    let url = getResourceURL(EndPoints.RESOURCE.PREDEFINED_UPDATE).replace('{ruleStackName}', request?.RuleStackName).replace('{name}', request.Name);
    return httpClient(url,
        {
            method: 'PUT',
            body: JSON.stringify({ Action: request.Action }),
        })
        .then((response) => {
            let ret: DataTypes.IFwaasApiResponse = {};
            let responseObj = response.json as DataModels.UpdatePrefixListResponse;
            if (isSuccess(responseObj)) {
                ret.data = responseObj.Response;
            } else {
                ret.error = buildError(response, "");
            }
            return ret;
        })
        .catch((err) => {
            return buildError(null, err);
        });
}


/**
 * CRUD operations for resource 'fileBlocking'
 */

async function listFileBlocking(request: any): Promise<DataTypes.IFwaasApiResponse> {
    let fileTypes: any = [];
    if(request?.typeSelected === 'Custom') {
        let customFileType = await listFileBlockingAction(request);
        fileTypes = await listFileBlockingType(request);

        if (customFileType.data) {
            fileTypes.data.FileTypesRunning.map((fileType: any) => {

                customFileType.data.map((obj: any) => {
                    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                    if (obj.FileType === fileType.FileType) {
                        fileType.Action = obj.FileBlockingCandidate.Action;
                        fileType.Direction = obj.FileBlockingCandidate.Direction;
                        fileType['Overridden'] = true;
                    };
                });

            })
        }
    } else {
        fileTypes = await listFileBlockingType(request);
    }

    return fileTypes;
}

async function listFileBlockingType(request: DataModels.ListFileBlockingTypesRequest, prevData: any = {}): Promise<DataTypes.IFwaasApiResponse> {
    let resourceUrl = getResourceURL(EndPoints.RESOURCE.FILEBLOCKING);
    if (request.NextToken) {
        resourceUrl = resourceUrl + `?nexttoken=${request.NextToken}`;
    }
    return httpClient(resourceUrl)
        .then(async (response: any) => {
            let ret: DataTypes.IFwaasApiResponse = {};
            let responseObj = response.json as DataModels.ListFileBlockingTypesResponse;
            if (isSuccess(responseObj)) {
                ret.data = responseObj.Response as any;
                if (!isEmpty(prevData)) {
                    ret.data.FileTypesRunning = [...ret.data.FileTypesRunning, ...prevData?.data?.FileTypesRunning];
                }
            } else {
                ret.error = buildError(response, "");
            }
            if (ret.data.NextToken && ret.data.NextToken !== "None") {
                request.NextToken = ret.data.NextToken;
                return await listFileBlockingType(request, ret);
            }
            return ret;
        })
        .catch((err) => {
            return buildError(null, err);
        });
}

async function listFileBlockingAction(request: DataModels.ListFileBlockingActionRequest, prevData: any = {}): Promise<DataTypes.IFwaasApiResponse> {
    let resourceUrl = getResourceURL(EndPoints.RESOURCE.FILEBLOCKING_CUSTOM).replace('{ruleStackName}', request?.RuleStackName);
    if (request.NextToken) {
        resourceUrl = resourceUrl + `?nexttoken=${request.NextToken}`;
    }
    return httpClient(resourceUrl)
        .then(async (response: any) => {
            let ret: DataTypes.IFwaasApiResponse = {};
            let responseObj = response.json as DataModels.ListFileBlockingActionResponse;
            let customActions: string[] = [];
            let customActionArr: any[] = [];
            if (isSuccess(responseObj)) {
                let index: number = Math.floor(100000 + Math.random() * 900000);
                //@ts-ignore
                let customActions = responseObj.Response?.FileBlockingCandidate as string[];
                if (customActions) {
                    await Promise.all(
                        customActions.map(async (FileType: any) =>
                            describeFileBlocking({ RuleStackName: request.RuleStackName, FileType: FileType })
                        )).then((response2) => {
                            response2.map((r: DataTypes.IFwaasApiResponse) => {
                                if (r.data) {
                                    let customFileType = r.data as any;
                                    customActionArr.push(customFileType);
                                }
                                //return r;
                                ret.data = customActionArr;
                            })
                        })
                }
            } else {
                ret.error = buildError(response, "");
            }
            return ret;
        })
        .catch((err) => {
            return buildError(null, err);
        });
}

async function updateFileBlocking(request: DataModels.UpdateFileBlockingActionRequest): Promise<DataTypes.IFwaasApiResponse> {
    let resourceUrl = getResourceURL(EndPoints.RESOURCE.FILEBLOCKING_CUSTOM).replace("{ruleStackName}", request.RuleStackName) + "/" + encodeURIComponent(request.FileType);
    return httpClient(resourceUrl, {
        method: 'PUT',
        //@ts-ignore
        body: JSON.stringify({ Action: request.Action || request?.DefaultAction, Direction: request.Direction || request?.DefaultDirection }),
    })
        .then((response) => {
            let ret: DataTypes.IFwaasApiResponse = {};
            let responseObj = response.json as DataModels.UpdateFileBlockingActionResponse;
            if (isSuccess(responseObj)) {
                ret.data = responseObj.Response?.FileBlockingEntry as any;
            } else {
                ret.error = buildError(response, "");
            }
            return ret;
        })
        .catch((err) => {
            return buildError(null, err);
        });
}

async function describeFileBlocking(request: DataModels.DescribeFileBlockingActionRequest): Promise<DataTypes.IFwaasApiResponse> {
    let resourceUrl = getResourceURL(EndPoints.RESOURCE.FILEBLOCKING_CUSTOM).replace("{ruleStackName}", request.RuleStackName) + "/" + encodeURIComponent(request.FileType);
    return httpClient(resourceUrl) // CHECK correct categories url call
        .then((response) => {
            let ret: DataTypes.IFwaasApiResponse = {};
            let responseObj = response.json as DataModels.DescribeFileBlockingActionResponse;
            if (isSuccess(responseObj)) {
                ret.data = responseObj.Response as any;
            } else {
                ret.error = buildError(response, "");
            }
            return ret;
        })
        .catch((err) => {
            return buildError(null, err);
        });
}

async function commit(request: DataModels.CommitEntry): Promise<DataTypes.IFwaasApiResponse> {
    let resourceUrl = getResourceURL(EndPoints.RESOURCE.RULESTACK) + "/" + request.ruleStackName + "/commit";
    return httpClient(resourceUrl, {
        method: 'POST',
    })
        .then((response) => {
            let ret: DataTypes.IFwaasApiResponse = {};
            let responseObj = response.json as DataModels.CommitResponse;
            if (isSuccess(responseObj)) {
                ret.data = responseObj.Response as DataTypes.IFwaasApiResponse;
            } else {
                ret.error = buildError(response, "");
            }
            return ret;
        })
        .catch((err) => {
            return buildError(null, err);
        });
}

async function revert(request: DataModels.RevertRequest): Promise<DataTypes.IFwaasApiResponse> {
    let resourceUrl = getResourceURL(EndPoints.RESOURCE.RULESTACK) + "/" + request.RuleStackName + "/revert";
    return httpClient(resourceUrl, {
        method: 'POST',
    })
        .then((response) => {
            let ret: DataTypes.IFwaasApiResponse = {};
            let responseObj = response.json as DataModels.RevertResponse;
            if (isSuccess(responseObj)) {
                ret.data = responseObj.Response as DataTypes.IFwaasApiResponse;
            } else {
                ret.error = buildError(response, "");
            }
            return ret;
        })
        .catch((err) => {
            return buildError(null, err);
        });
}


async function validate(request: DataModels.ValidateRequest): Promise<DataTypes.IFwaasApiResponse> {
    let resourceUrl = getResourceURL(EndPoints.RESOURCE.RULESTACK) + "/" + request.RuleStackName + "/validate";
    return httpClient(resourceUrl, {
        method: 'POST',
    })
        .then((response) => {
            let ret: DataTypes.IFwaasApiResponse = {};
            let responseObj = response.json as DataModels.ValidateResponse;
            if (isSuccess(responseObj)) {
                ret.data = responseObj.Response as DataTypes.IFwaasApiResponse;
            } else {
                ret.error = buildError(response, "");
            }
            return ret;
        })
        .catch((err) => {
            return buildError(null, err);
        });
}

async function commitstatus(request: DataModels.DescribeCommitRequest): Promise<DataTypes.IFwaasApiResponse> {
    let resourceUrl = getResourceURL(EndPoints.RESOURCE.RULESTACK) + "/" + request.RuleStackName + "/commit";
    return httpClient(resourceUrl, {
        method: 'GET',
    })
        .then((response) => {
            let ret: DataTypes.IFwaasApiResponse = {};
            let responseObj = response.json as DataModels.DescribeCommitResponse;
            if (isSuccess(responseObj)) {
                ret.data = responseObj.Response as DataTypes.IFwaasApiResponse;
            } else {
                ret.error = buildError(response, "");
            }
            return ret;
        })
        .catch((err) => {
            return buildError(null, err);
        });
}


async function listPermissionPolicies(request: DataModels.ListPermissionPoliciesRequest): Promise<DataTypes.IFwaasApiResponse> {
    let reourceUrl = getResourceURL(EndPoints.RESOURCE.PERMISSION_POLICIES);
    const controller = new AbortController();
    pushController('permissionpolicies', controller);

    return httpClient(reourceUrl, { signal: controller.signal })
        .then((response) => {
            let ret: DataTypes.IFwaasApiResponse = {};
            let responseObj = response.json as DataModels.ListPermissionPoliciesResponse;
            if (isSuccess(responseObj)) {
                ret.data = responseObj.Response as string[];
            } else {
                ret.error = buildError(response, "");
            }
            return ret;
        })
        .catch((err) => {
            return buildError(null, err, { noToast: controller.signal.aborted });
        });
}

async function listApplicationDetail(request: DataModels.ListAppIdVersionsRequest): Promise<DataTypes.IFwaasApiResponse> {
    let resourceUrl = getResourceURL(EndPoints.RESOURCE.APPLICATIONS);
    return httpClient(resourceUrl)
        .then(async (response: any) => {
            let ret: DataTypes.IFwaasApiResponse = {};
            let appversionList: { id: number, Application: string[] }[] = [];
            let responseObj = response.json as DataModels.ListAppIdVersionsResponse;

            if (isSuccess(responseObj)) {
                let index: number = Math.floor(100000 + Math.random() * 900000);
                let appIdVersions = responseObj.Response?.AppIdVersions as string[];
                if (appIdVersions) {
                    await Promise.all(
                        appIdVersions.map(async (appIdVersion: string) => describeAppIdVersions(
                            {
                                AppIdVersion: appIdVersion,
                            }))
                    ).then((response2) => {
                        response2.map((r: DataTypes.IFwaasApiResponse) => {
                            if (r.data) {
                                let applications = r.data as { id: number, Application: string[] };
                                applications.id = index++;
                                appversionList.push(applications);
                            }
                            return r;
                        })
                    })

                    ret.data = appversionList;
                }
            } else {
                ret.error = buildError(response, "");
            }
            return ret;
        })
        .catch((err) => {
            return buildError(null, err);
        });
}

async function describeAppIdVersions(request: DataModels.DescribeAppIdVersionRequest, prevData: any = {}): Promise<DataTypes.IFwaasApiResponse> {
    let resourceUrl = getResourceURL(EndPoints.RESOURCE.APPLICATIONS) + "/" + request.AppIdVersion;
    if (request.NextToken) {
        resourceUrl = resourceUrl + `?nexttoken=${request.NextToken}`;
    }
    return httpClient(resourceUrl)
        .then(async (response: any) => {
            let ret: DataTypes.IFwaasApiResponse = {};

            let responseObj = response.json as DataModels.DescribeAppIdVersionResponse;
            if (isSuccess(responseObj)) {
                ret.data = responseObj.Response as {
                    AppIdVersion?: string;
                    Applications?: string[];
                    NextToken?: string;
                };
                if (!isEmpty(prevData)) {
                    ret.data.Applications = [...ret.data.Applications, ...prevData?.data?.Applications];
                }
            } else {
                ret.error = buildError(response, "");
            }
            if (ret.data.NextToken) {
                request.NextToken = ret.data.NextToken;
                return await describeAppIdVersions(request, ret);
            }
            return ret;
        })
        .catch((err) => {
            return buildError(null, err);
        });
}

async function describeLogProfile(request: DataModels.ReadFWResourceLogProfileRequest): Promise<DataTypes.IFwaasApiResponse> {
    let resourceUrl = getResourceURL(EndPoints.RESOURCE.LOGPROFILE).replace('{ngfirewallname}', request?.FirewallName);
    if (request.AccountId) {
        resourceUrl = resourceUrl + `?accountid=${request.AccountId}`;
    }
    return httpClient(resourceUrl)
        .then((response) => {
            let ret: DataTypes.IFwaasApiResponse = {};
            let responseObj = response.json as DataModels.ReadFWResourceLogProfileResponse;
            if (isSuccess(responseObj)) {
                ret.data = responseObj.Response as [DataModels.LogProfileConfig];
            } else {
                ret.error = buildError(response, "");
            }
            return ret;
        })
        .catch((err) => {
            return buildError(null, err);
        });
}

async function updateLogProfile(request: DataModels.UpdateFWResourceLogProfileRequest): Promise<DataTypes.IFwaasApiResponse> {
    let url = getResourceURL(EndPoints.RESOURCE.LOGPROFILE).replace('{ngfirewallname}', request?.FirewallName);

    interface payloadInterface extends Omit<DataModels.UpdateFWResourceLogProfileRequest, 'FirewallName'> { }
    let payload = request as payloadInterface;
    payload['AccountId'] = request.AccountId;

    //@ts-ignore
    if (payload['FirewallName']) {
        //@ts-ignore
        delete payload['FirewallName'];
    }

    return httpClient(url,
        {
            method: 'PUT',
            body: JSON.stringify(payload),
        })
        .then((response) => {
            let ret: DataTypes.IFwaasApiResponse = {};
            let responseObj = response.json as DataModels.UpdateFWResourceLogProfileResponse;
            if (isSuccess(responseObj)) {
                ret.data = responseObj.Response?.LogDestinationConfigs as [DataModels.LogProfileConfig] || [];
                // ret.data.id = Math.floor(Math.random() * 50000);
            } else {
                ret.error = buildError(response, "");
            }
            return ret;
        })
        .catch((err) => {
            return buildError(null, err);
        });
}

async function listSubscription(request: DataModels.ListSubscriptionRequest): Promise<DataTypes.IFwaasApiResponse> {
    let resourceUrl = getResourceURL(EndPoints.RESOURCE.SUBSCRIPTION);
    //@ts-ignore
    const urlParams = payloadToLowercaseParams(request);

    return httpClient(resourceUrl + '?' + urlParams.toString())
        .then(async (response) => {
            let responseObj = response.json as DataModels.ListSubscriptionResponse;
            let ret: DataTypes.IFwaasApiResponse = {};
            if (isSuccess(responseObj)) {
                ret.data = responseObj.Response?.Subscriptions as any[];
            } else {
                ret.error = buildError(response, "");
            }
            return ret;
        })
        .catch((err) => {
            return { error: buildError(null, err) }
        });
}

async function createSubscription(request: DataModels.CreateSubscribeRequest): Promise<DataTypes.IFwaasApiResponse> {
    let resourceUrl = getResourceURL(EndPoints.RESOURCE.SUBSCRIPTION);
    return httpClient(resourceUrl,
        {
            method: 'POST',
            body: JSON.stringify(request),
        })
        .then(async (response) => {
            let responseObj = response.json as DataModels.CreateSubscribeResponse;
            let ret: DataTypes.IFwaasApiResponse = {};
            if (isSuccess(responseObj)) {
                ret.data = responseObj.Response as any;
            } else {
                ret.error = buildError(response, "");
            }
            return ret;
        })
        .catch((err) => {
            return { error: buildError(null, err) }
        });
}

async function createVpcGroup(request: DataModels.CreateVpcGroupRequest): Promise<DataTypes.IFwaasApiResponse> {
    let resourceUrl = getResourceURL(EndPoints.RESOURCE.VPCGROUPS);
    return httpClient(resourceUrl,
        {
            method: 'POST',
            body: JSON.stringify(request),
        })
        .then(async (response) => {
            let responseObj = response.json as DataModels.CreateSubscribeResponse;
            let ret: DataTypes.IFwaasApiResponse = {};
            if (isSuccess(responseObj)) {
                ret.data = responseObj.Response as any;
            } else {
                ret.error = buildError(response, responseObj);
            }
            return ret;
        })
        .catch((err) => {
            return { error: buildError(null, err) }
        });
}

/*
 * Utility methods
 */
function getResourceURL(resource: EndPoints.RESOURCE, forceApiUrl="", region?: string | null) {
    /**
      * Domain // TBD : Believe there will be a single end point URL for both mgmt and config?
      */
    let MGMT_URL: string = ApplicationConfigManager.getInstance().getAPIBaseUri();
    let CFG_URL: string = ApplicationConfigManager.getInstance().getAPIBaseUri();

    let BASE_URL: string = region
    ? ApplicationConfigManager.getInstance().getRegionAPIBaseUri(region)
    : ApplicationConfigManager.getInstance().getAPIBaseUri();

    switch (resource) {
        case EndPoints.RESOURCE.ACCOUNT:
            return MGMT_URL + EndPoints.URI_ACCOUNTS;
        case EndPoints.RESOURCE.USER:
            return MGMT_URL + EndPoints.URI_USERS;
        case EndPoints.RESOURCE.FIREWALL:
            return CFG_URL + EndPoints.URI_FIREWALLS;
        case EndPoints.RESOURCE.COUNTRY:
            return CFG_URL + EndPoints.URI_COUNTRIES;
        case EndPoints.RESOURCE.RULESTACK:
            return BASE_URL + EndPoints.URI_RULESTACKS;
        case EndPoints.RESOURCE.FEED:
            return CFG_URL + EndPoints.URI_FEED;
        case EndPoints.RESOURCE.RULES:
            return CFG_URL + EndPoints.URI_RULES;
        case EndPoints.RESOURCE.PREFIXLIST:
            return CFG_URL + EndPoints.URI_PREFIXLIST;
        case EndPoints.RESOURCE.FQDNLIST:
            return CFG_URL + EndPoints.URI_FQDNLIST;
        case EndPoints.RESOURCE.CATEGORY:
            return CFG_URL + EndPoints.URI_CATEGORIES;
        case EndPoints.RESOURCE.CERTIFICATE:
            return CFG_URL + EndPoints.URI_CERTIFICATE;
        case EndPoints.RESOURCE.PREDEFINED:
            return CFG_URL + EndPoints.URI_PREDEFINED;
        case EndPoints.RESOURCE.PREDEFINED_OVERRIDE:
            return CFG_URL + EndPoints.URI_PREDEFINED_OVERRIDE;
        case EndPoints.RESOURCE.PREDEFINED_UPDATE:
            return CFG_URL + EndPoints.URI_PREDEFINED_UPDATE;
        case EndPoints.RESOURCE.FILEBLOCKING:
            return CFG_URL + EndPoints.URI_FILEBLOCKING;
        case EndPoints.RESOURCE.FILEBLOCKING_CUSTOM:
            return CFG_URL + EndPoints.URI_FILEBLOCKING_CUSTOM;
        case EndPoints.RESOURCE.XACCOUNT_ROLES:
            return MGMT_URL + EndPoints.URI_XACCOUNT_ROLES;
        case EndPoints.RESOURCE.PERMISSION_POLICIES:
            return MGMT_URL + EndPoints.URI_PERMISSION_POLICIES;
        case EndPoints.RESOURCE.APPLICATIONS:
            return CFG_URL + EndPoints.APPID_VERSIONS;
        case EndPoints.RESOURCE.LOGPROFILE:
            return CFG_URL + EndPoints.LOGPROFILE;
        case EndPoints.RESOURCE.TOKEN:
            return CFG_URL + EndPoints.URI_TOKEN;
        case EndPoints.RESOURCE.SUPPORT:
            return CFG_URL + EndPoints.URI_SUPPORT;
        case EndPoints.RESOURCE.RULE_COUNTERS:
            return CFG_URL + EndPoints.URI_RULE_COUNTERS;
        case EndPoints.RESOURCE.RULE_COUNTERS_RESET:
            return CFG_URL + EndPoints.URI_RULE_COUNTERS_RESET
        case EndPoints.RESOURCE.SUBSCRIPTION:
            return MGMT_URL + EndPoints.URI_SUBSCRIPTION;
        case EndPoints.RESOURCE.TAGS:
            return MGMT_URL + EndPoints.URI_TAGS;
        case EndPoints.RESOURCE.SETTINGS:
            return forceApiUrl ? forceApiUrl + EndPoints.URI_SETTINGS : MGMT_URL + EndPoints.URI_SETTINGS;
        case EndPoints.RESOURCE.BILLING:
            return MGMT_URL + EndPoints.URI_BILLING;
        case EndPoints.RESOURCE.VPCS:
            return MGMT_URL + EndPoints.VPCS;
        case EndPoints.RESOURCE.VPCTAGS:
            return MGMT_URL + EndPoints.VPCTAGS;
        case EndPoints.RESOURCE.VPCTAGIPS:
            return MGMT_URL + EndPoints.VPCTAGIPS;
        case EndPoints.RESOURCE.VPCPREFIXTAGS:
            return MGMT_URL + EndPoints.VPCPREFIXTAGS;
        case EndPoints.RESOURCE.VPCGROUPS:
            return MGMT_URL + EndPoints.VPCGROUPS;
        case EndPoints.RESOURCE.PANORAMA:
            return MGMT_URL + EndPoints.URI_PANORAMA;
        case EndPoints.RESOURCE.CLOUDMANAGER:
            return MGMT_URL + EndPoints.URI_CLOUDMANAGER;
        case EndPoints.RESOURCE.INTEGRATIONS:
            return MGMT_URL + EndPoints.URI_INTEGRATIONS;
        case EndPoints.RESOURCE.REGIONS:
            return MGMT_URL + EndPoints.URI_REGIONS;
        case EndPoints.RESOURCE.DEVICE_GROUPS:
            return MGMT_URL + EndPoints.URI_DEVICE_GROUPS;
        case EndPoints.RESOURCE.DLP_LINKS:
            return MGMT_URL + EndPoints.URI_DLP_LINKS;
        case EndPoints.RESOURCE.CHECK_USER:
            return MGMT_URL + EndPoints.URI_CHECK_USER;
        case EndPoints.RESOURCE.USER_MIGRATION:
            return MGMT_URL + EndPoints.URI_USER_MIGRATION;
        default:
            console.log("Unsupported resource " + resource);
            return "";
    }
}

async function checkOktaUser(request: DataModels.CheckOktaPayload): Promise<DataTypes.IFwaasApiResponse> {
    const {UserName} = request;
    let resourceUrl = getResourceURL(EndPoints.RESOURCE.CHECK_USER) + `?username=${UserName}`;
    return httpClient(resourceUrl,
        {
            method: 'GET',
        })
        .then(async (response: any) => {
            let responseObj = response.json;
            let ret: DataTypes.IFwaasApiResponse = {};
            if (isSuccess(responseObj)) {
                ret.data = responseObj.Response as any;
            } else {
                ret.error = buildError(response, "");
            }
            return ret;
        })
        .catch((err) => {
            return { error: buildError(null, err) }
        });
}
